import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { Button } from "components/Button";
import { CommentsTextArea } from "components/CommentsTextArea";
import { ResearchResponseDTO } from "dtos/ResearchResponseDTO";
import { useToast } from "hooks/toast";

import profizLogo from "assets/icons/logo-color.svg";
import linearDocument from "assets/icons/linear-document.svg";

import apiv2 from "services/apiv2";
import * as S from "./styles";
import { LoadingProfiz } from "components/LoadingProfiz";
import { containsEscapeCharacters } from "utils/containsEscapeCharacters ";

export function ExecuteServiceOrder() {
  const { addToast } = useToast();
  const history = useHistory();
  const hash = history.location.pathname.split("/")[2];

  const [researchData, setResearchData] = useState({} as ResearchResponseDTO);
  const [comments, setComments] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [loadingButton, setLoadingButton] = useState(false);
  const [hasErrorOnComments, setHasErrorOnComments] = useState("");

  const [executeService, setExecuteService] = useState({
    yes: false,
    not: false,
  });
  const { yes, not } = executeService;
  const verifyExecuteService = !yes && !not;

  useEffect(() => {
    async function fetchResearchData() {
      try {
        const response = await apiv2.get<ResearchResponseDTO>(
          `budgets/service-order/research/${hash}`
        );

        if (response.data.status === "answered") {
          history.push(`/research-form/${hash}/success`);
        }

        setResearchData(response.data);
      } catch (error: any) {
        history.push("/");

        addToast({
          type: "error",
          title: "Erro ao buscar dados da pesquisa",
          description: error.response.data.details[0],
        });
      } finally {
        setIsLoading(false);
      }
    }

    fetchResearchData();
  }, []); // eslint-disable-line

  function handleToggleExecuteService(answer: boolean) {
    setExecuteService({
      yes: answer,
      not: !answer,
    });
  }

  function handleStartRating() {
    history.push(`/research-form/${hash}/rating`);
  }

  async function handleUncompletedResearch() {
    try {
      if (containsEscapeCharacters(comments)) {
        setHasErrorOnComments('Não é permitido uso de barra invertida "\\"');
        addToast({
          title: "Ops!!",
          description: 'Não é permitido uso de barra invertida "\\"',
          type: "error",
        });

        return;
      } else {
        setHasErrorOnComments("");
      }

      setLoadingButton(true);
      const researchFormData = {
        stars: 0,
        executed: false,
        observations: comments,
        visible: true,
      };

      await apiv2.put(
        `budgets/service-order/research/${hash}`,
        researchFormData
      );

      history.replace(`/research-form/${hash}/success`, {});
    } catch (error) {
      addToast({
        type: "error",
        title: "Ops...",
        description: "Ocorreu um erro ao finalizar a pesquisa",
      });
    } finally {
      setLoadingButton(false);
    }
  }

  return (
    <S.ScrollView>
      <S.Container>
        {isLoading ? (
          <LoadingProfiz isVisible={isLoading} positionLogo="40%" />
        ) : (
          <S.Content>
            <S.Header>
              <S.LogoProfiz
                isActive={verifyExecuteService}
                src={profizLogo}
                alt="Profiz"
              />
            </S.Header>

            <S.WrapperInfo isActive={verifyExecuteService}>
              <S.Title>Olá, {researchData.serviceOrder.client.name}!</S.Title>

              <S.Text>
                Essa é a pesquisa de satisfação de serviços prestados por{" "}
                {researchData.serviceProviderUsername}, cadastrado(a) na Profiz.{" "}
                <br />É muito importante saber sua opinião e avaliação!
              </S.Text>

              <br />
              <br />
              <br />

              <S.Text>
                Clique abaixo para visualizar os detalhes da sua Ordem de
                Serviço.
              </S.Text>
            </S.WrapperInfo>

            <a
              href={researchData.serviceOrder.detailLink}
              target="_blank"
              rel="noreferrer"
              style={{ textDecoration: "none" }}
            >
              <S.ButtonText isActive={verifyExecuteService}>
                <span>
                  Ordem de serviço nº{researchData.serviceOrder.sequenceNumber}
                </span>
                <img
                  src={linearDocument}
                  alt="link para download da ordem de serviço"
                />
              </S.ButtonText>
            </a>

            <S.WrapperAction isActive={verifyExecuteService}>
              <S.Subtitle isActive={verifyExecuteService}>
                Vamos começar!
              </S.Subtitle>

              <div>
                <S.Title>O serviço foi executado?</S.Title>
                <S.WrapperButtons>
                  <S.Button
                    isActive={yes}
                    onClick={() => handleToggleExecuteService(true)}
                  >
                    Sim
                  </S.Button>
                  <S.Button
                    isActive={not}
                    onClick={() => handleToggleExecuteService(false)}
                  >
                    Não
                  </S.Button>
                </S.WrapperButtons>
              </div>

              {yes && (
                <S.Wrapper>
                  <Button onClick={handleStartRating}>Começar avaliação</Button>
                </S.Wrapper>
              )}

              {not && (
                <S.WrapperForm>
                  <div>
                    <S.Title>Adicione um comentário</S.Title>
                    <S.Text>Conte-nos mais sobre sua experiência.</S.Text>
                  </div>

                  <CommentsTextArea
                    style={{ height: "150px" }}
                    value={comments}
                    onChange={(e) => setComments(e.target.value)}
                    placeholder="Escreva aqui seu comentário"
                    hasError={hasErrorOnComments}
                  />

                  <S.WrapperButton>
                    <Button
                      loading={loadingButton}
                      onClick={handleUncompletedResearch}
                    >
                      Enviar
                    </Button>
                  </S.WrapperButton>
                </S.WrapperForm>
              )}
            </S.WrapperAction>
          </S.Content>
        )}
      </S.Container>
    </S.ScrollView>
  );
}
