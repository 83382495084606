import { LoadingProfiz } from "components/LoadingProfiz";
import React, { useEffect, useState } from "react";

import * as S from "./styles";
import { FiChevronLeft } from "react-icons/fi";

import { useTheme } from "styled-components";
import { useToast } from "hooks/toast";
import { EnvironmentPageProps, Page } from "../EnvironmentFlow/types";
import { ModalPageProps } from "components/ModalPageClients/types";
import { Input } from "components/Input";
import { Button } from "components/Button";
import axios from "axios";
import { Dropdown, DropdownOption } from "components/DropDown";
import { CommentsTextArea } from "components/CommentsTextArea";
import { api } from "services/api";
import { useEnvironment } from "hooks/pmoc/environmentPmoc";
import { useServiceFormTree } from "hooks/serviceFormTree";
import { useServiceDropDown } from "hooks/servicesDropDown";
import { ServiceName } from "components/ModalServices/Areas/ServiceName";
import { useServiceChecklist } from "hooks/serviceChecklist";
import { CardChecklist } from "components/CardChecklist";
import { EditButton } from "components/EditButton";
import { DeleteButton } from "components/DeleteButton";
import { AlertModal } from "components/AlertModal";
import { ChecklistItemList } from "pages/fieldService/ServiceChecklist/types";
import { usePmoc } from "hooks/usePmoc";
import { containsEscapeCharacters } from "utils/containsEscapeCharacters ";
type PageProps = {
  handleChooseEditModal?: ModalPageProps;
  handleChooseEnvironment?: EnvironmentPageProps;
  isEditService?: boolean;
};

type ExtensionProps = {
  id: number;
  name: string;
};

const runTimeExtensionOptions = [
  { id: 1, name: "Minutos" },
  { id: 2, name: "Horas" },
  { id: 3, name: "Dias" },
];

const warrantyExtensionOptions = [
  { id: 1, name: "Dias" },
  { id: 2, name: "Meses" },
  { id: 3, name: "Anos" },
];

const DROPDOWN_OPTIONS = [
  {
    id: 1,
    name: "Semanal",
  },
  {
    id: 2,
    name: "Quinzenal",
  },
  {
    id: 3,
    name: "Mensal",
  },
  {
    id: 4,
    name: "Bimestral",
  },
  {
    id: 5,
    name: "Trimestral",
  },
  {
    id: 6,
    name: "Semestral",
  },
  {
    id: 7,
    name: "Anual",
  },
];

export function PmocServiceForm({
  handleChooseEditModal,
  handleChooseEnvironment,
  isEditService = false,
}: PageProps) {
  const { addToast } = useToast();
  const [loadingModal, setLoadingModal] = useState(false);
  const theme = useTheme();

  const { initialPmocProps } = usePmoc();

  const {
    selectedCategoryId,
    selectedService,
    handleCategoryId,
    handleService,
    frequencyIdDropdown,
    handleSetFrequencyIdDropdown,
    runTime,
    handleSetRunTime,
    runTimeExtension,
    handleSetRunTimeExtension,
    warrantyTime,
    handleSetWarrantyTime,
    observationText,
    handleSetObservationText,
    warrantyExtension,
    handleSetWarrantyExtension,
    priceService,
    handleSetPriceService,
    unMaskedPrice,
    handleSetUnmaskedPrice,
    reset,
  } = useServiceDropDown();

  const {
    equipment,
    pmocService,
    popStackNavigation,
    popAndDeleteStackNavigation,
    pushStackNavigation,
  } = useEnvironment();

  const {
    handleSetChecklistDetail,
    handleSetIsNewChecklist,
    handleSetEditPage,
    handleSetSelectedItemChecklist,
    selectedItemChecklist,
    handleSetIsNotMenusAccess,
  } = useServiceChecklist();

  const [hasErrorOnCategory, setHasErrorOnCategory] = useState("");

  const [hasErrorOnRunTime, setHasErrorOnRunTime] = useState("");
  const [hasErrorOnRuntimeExtension, setHasErrorOnRuntimeExtension] =
    useState("");
  const [hasErrorOnWarrantyTime, setHasErrorOnWarrantyTime] = useState("");
  const [hasErrorOnWarrantyExtension, setHasErrorOnWarrantyExtension] =
    useState("");

  const [hasErrorOnFrequency, setHasErrorOnFrequency] = useState("");
  const [hasErrorOnService, setHasErrorOnService] = useState("");

  const [hasErrorOnPrice, setHasErrorOnPrice] = useState("");
  const { categoriesTree, handleSetCategoriesTree } = useServiceFormTree();

  const [indexDelete, setIndexDelete] = useState<number>();
  const [isOpenAlertModalDelete, setIsOpenAlertModalDelete] = useState(false);
  const [hasErrorOnObservationText, setHasErrorOnObservationText] =
    useState("");

  useEffect(() => {
    setDropdownInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function setDropdownInfo() {
    try {
      setLoadingModal(true);

      const route = "services/new-service-form";
      const { data } = await api.get(
        equipment.category.id
          ? `${route}?categoryId=${equipment.category.id}`
          : route
      );

      handleSetCategoriesTree(data.categoriesTree);

      if (isEditService && pmocService && Object.keys(pmocService).length > 1) {
        pmocService.serviceCategory &&
          handleCategoryId(pmocService.serviceCategory.id);
        let frequencyOption =
          pmocService.frequency &&
          DROPDOWN_OPTIONS.find(
            (element) => element.name.toLowerCase() === pmocService.frequency
          );

        handleSetFrequencyIdDropdown(frequencyOption as DropdownOption);

        selectedItemChecklist.length < 1 &&
          pmocService.serviceChecklist &&
          handleSetSelectedItemChecklist(pmocService.serviceChecklist);

        pmocService.serviceType && handleService(pmocService.serviceType);

        if (pmocService.price && pmocService.price > 0) {
          handleSetPriceService(pmocService.price);
          handleSetUnmaskedPrice(pmocService.price);
        }

        pmocService.serviceSteps &&
          handleSetObservationText(pmocService.serviceSteps);

        if (
          pmocService.runtime &&
          Object.keys(pmocService.runtime).length > 0
        ) {
          pmocService.runtime &&
            pmocService.runtime.time &&
            handleSetRunTime(pmocService.runtime.time.toString());
          let findRunTimeExtension = runTimeExtensionOptions.find(
            (currentRunTimeExtension) =>
              currentRunTimeExtension.name === pmocService.runtime?.extension
          );
          findRunTimeExtension &&
            handleSetRunTimeExtension(findRunTimeExtension);
        }
        if (
          pmocService.warranty &&
          Object.keys(pmocService.warranty).length > 0
        ) {
          pmocService.warranty &&
            pmocService.warranty.time &&
            handleSetWarrantyTime(pmocService.warranty.time);
          let findWarrantyExtension = warrantyExtensionOptions.find(
            (currentWarrantyExtension) =>
              currentWarrantyExtension.name === pmocService.warranty?.extension
          );
          findWarrantyExtension &&
            handleSetWarrantyExtension(findWarrantyExtension);
        }
      }
    } catch (error) {
      addToast({
        title: "Erro",
        description:
          axios.isAxiosError(error) && error.response?.data.error
            ? error.response.data.error
            : "Não foi possível carregar os tipos de serviço.",
        type: "error",
      });
    } finally {
      setLoadingModal(false);
    }
  }

  const handleClearInfos = () => {
    handleSetPriceService(0);
    handleSetUnmaskedPrice(0);
    handleSetObservationText("");
    reset();
    return;
  };

  const SaveInfoServices = async () => {
    if (!selectedCategoryId) {
      setHasErrorOnCategory("Categoria é obrigatório");
    } else {
      setHasErrorOnCategory("");
    }

    if (!selectedService.id) {
      setHasErrorOnService("Serviço é obrigatório");
    } else {
      setHasErrorOnService("");
    }

    if (
      !frequencyIdDropdown.id ||
      Object.keys(frequencyIdDropdown).length < 1
    ) {
      setHasErrorOnFrequency("A periodicidade é obrigatório");
    } else {
      setHasErrorOnFrequency("");
    }

    const runTimeFormatted = Number(runTime);
    const warrantyTimeFormatted = Number(warrantyTime);

    if (runTimeFormatted <= 0 && runTimeExtension.name) {
      setHasErrorOnRunTime("Digite o tempo de execução.");
    } else {
      setHasErrorOnRunTime("");
    }

    if (runTimeFormatted > 0 && !runTimeExtension.name) {
      setHasErrorOnRuntimeExtension("Selecione uma das opções");
    } else {
      setHasErrorOnRuntimeExtension("");
    }

    if (warrantyTimeFormatted <= 0 && warrantyExtension.name) {
      setHasErrorOnWarrantyTime("Digite o tempo de garantia.");
    } else {
      setHasErrorOnWarrantyTime("");
    }

    if (warrantyTimeFormatted > 0 && !warrantyExtension.name) {
      setHasErrorOnWarrantyExtension("Selecione uma das opções");
    } else {
      setHasErrorOnWarrantyExtension("");
    }

    if (containsEscapeCharacters(observationText)) {
      setHasErrorOnObservationText(
        'Não é permitido uso de barra invertida "\\"'
      );
      addToast({
        title: "Ops!!",
        description: 'Não é permitido uso de barra invertida "\\"',
        type: "error",
      });

      return;
    } else {
      setHasErrorOnObservationText("");
    }

    if (
      !selectedCategoryId ||
      !selectedService.id ||
      !frequencyIdDropdown ||
      Object.keys(frequencyIdDropdown).length < 1 ||
      (runTimeFormatted <= 0 && runTimeExtension.name) ||
      (runTimeFormatted > 0 && !runTimeExtension.name) ||
      (warrantyTimeFormatted <= 0 && warrantyExtension.name) ||
      (warrantyTimeFormatted > 0 && !warrantyExtension.name)
    ) {
      addToast({
        title: "Ops!!",
        description: "Preencha o campos obrigatórios.",
        type: "error",
      });
      return;
    }

    try {
      setLoadingModal(true);
      let serviceObj = {
        categoryId: selectedCategoryId,
        serviceTypeId: selectedService.id,
        frequency: frequencyIdDropdown.name.toLocaleLowerCase(),
        price: unMaskedPrice,
        equipmentId: equipment.id,
        runtime: {
          ...(runTimeFormatted !== 0 ? { time: runTimeFormatted } : {}),
          ...(runTimeExtension.name
            ? { extension: runTimeExtension.name }
            : {}),
        },
        warranty: {
          ...(warrantyTimeFormatted !== 0
            ? { time: warrantyTimeFormatted }
            : {}),
          ...(warrantyExtension.name
            ? { extension: warrantyExtension.name }
            : {}),
        },
        serviceSteps: observationText,
        checklistId:
          selectedItemChecklist.length > 0 ? selectedItemChecklist[0].id : 0,
      };

      if (isEditService && popStackNavigation() === "detailEquipment") {
        if (!pmocService.serviceIdPmoc) {
          addToast({
            title: "Erro",
            description: "Não foi possível atualizar o serviço do equipamento.",
            type: "error",
          });
          return;
        }

        let serviceEquipment = JSON.parse(
          JSON.stringify({
            ...serviceObj,
            description: serviceObj.serviceSteps,
          })
        );
        const {
          categoryId,
          serviceTypeId,
          frequency,
          equipmentId,
          serviceSteps,
          ...rest
        } = serviceEquipment;

        await api.put(
          `pmoc/${Number(initialPmocProps.id)}/service/${Number(
            pmocService.serviceIdPmoc
          )}`,
          {
            ...rest,
            editAllRegisterIfSameModelService: false,
          }
        );

        addToast({
          title: "Sucesso",
          description: "Serviço atualizado com sucesso.",
          type: "success",
        });
        handleBackButton();
        return;
      }

      isEditService
        ? await api.put(`pmoc/service/${Number(pmocService.id)}`, serviceObj)
        : await api.post(`pmoc/service`, serviceObj);

      if (isEditService) {
        addToast({
          title: "Sucesso",
          description: "Serviço atualizado com sucesso.",
          type: "success",
        });
        handleBackButton();
        return;
      }
      addToast({
        title: "Sucesso",
        description: "Serviço criado com sucesso.",
        type: "success",
      });

      handleBackButton();
    } catch (error) {
      addToast({
        title: "Erro",
        description:
          axios.isAxiosError(error) && error.response?.data.error
            ? error.response.data.error
            : "Não foi salvar as informações do serviço.",
        type: "error",
      });
    } finally {
      setLoadingModal(false);
    }
  };

  function handleSelectedFrequency(frequency: DropdownOption) {
    if (frequencyIdDropdown && frequencyIdDropdown.id === frequency.id) return;
    handleSetFrequencyIdDropdown(frequency);

    setHasErrorOnFrequency("");
  }

  function handleWarrantyExtension({ id }: DropdownOption) {
    const findCurrentExtension = warrantyExtensionOptions.find(
      (extension) => extension.id === id
    );

    if (findCurrentExtension) {
      handleSetWarrantyExtension(findCurrentExtension);
    }

    setHasErrorOnWarrantyExtension("");
  }

  function handleSelectRuntimeExtension({ id }: DropdownOption) {
    const findCurrentExtensionRunTime = runTimeExtensionOptions.find(
      (extension) => extension.id === id
    );

    if (findCurrentExtensionRunTime) {
      handleSetRunTimeExtension(findCurrentExtensionRunTime);
    }

    setHasErrorOnRuntimeExtension("");
  }

  function handleClearRunTimeExtension() {
    handleSetRunTimeExtension({} as ExtensionProps);
  }

  function handleClearWarrantyExtension() {
    handleSetWarrantyExtension({} as ExtensionProps);
  }

  function handleBackButton() {
    let beforePage = popAndDeleteStackNavigation();
    if (beforePage.length > 0) {
      handleChooseEnvironment && handleChooseEnvironment(beforePage as Page);
      handleSetSelectedItemChecklist([]);
      handleClearInfos();
      return;
    }
    handleClearInfos();
    return;
  }

  function handleSelectCategory({ id }: DropdownOption) {
    if (Number(selectedCategoryId) === Number(id)) {
      return;
    }
    handleCategoryId(id);
    handleService({} as DropdownOption);
    setHasErrorOnCategory("");
  }

  function handleAddChecklist() {
    handleSetIsNotMenusAccess(true);
    pushStackNavigation(isEditService ? "editService" : "createService");
    return (
      handleChooseEnvironment && handleChooseEnvironment("serviceChecklist")
    );
  }

  function handleDeleteRegister(ind: number) {
    let filterChecklist = JSON.parse(
      JSON.stringify(selectedItemChecklist)
    ).filter((checklist: ChecklistItemList) => {
      return Number(checklist.id) !== Number(ind);
    });
    handleSetSelectedItemChecklist(filterChecklist);
  }

  async function detailItem(currentChecklist: ChecklistItemList) {
    try {
      handleSetChecklistDetail(currentChecklist);
      handleSetIsNewChecklist(false);

      if (handleChooseEnvironment) {
        pushStackNavigation(isEditService ? "editService" : "createService");
        handleChooseEnvironment("checklistPage");
      }
    } catch (error) {
      addToast({
        title: "Ops!!",
        description: "Não foi possível obter os detalhes do checklist.",
        type: "error",
      });
    } finally {
    }
  }

  return (
    <>
      {loadingModal ? (
        <LoadingProfiz isVisible={loadingModal} />
      ) : (
        <S.Container>
          <AlertModal
            isVisible={isOpenAlertModalDelete}
            title="Deseja apagar?"
            description={"Deseja realmente apagar esse checklist?"}
            action="choose"
            handleConfirm={() => {
              handleDeleteRegister(indexDelete as number);
              setIsOpenAlertModalDelete(false);
            }}
            onCloseModal={() => setIsOpenAlertModalDelete(false)}
          />

          <S.Top>
            <S.BackButton
              onClick={() => {
                handleBackButton();
              }}
            >
              <FiChevronLeft size={16} color={theme.colors.text} />
            </S.BackButton>
            <S.Title>
              {!isEditService
                ? "Novo serviço de PMOC"
                : "Editar serviço de PMOC"}
            </S.Title>
          </S.Top>

          <S.Wrapper>
            <Dropdown
              disabled={
                isEditService && popStackNavigation() === "detailEquipment"
              }
              label="Categoria*"
              placeholder="Selecione uma categoria"
              options={categoriesTree}
              categoryIdSelected={selectedCategoryId}
              name={"category"}
              // eslint-disable-next-line react/jsx-no-bind
              onClickedValue={handleSelectCategory}
              hasError={hasErrorOnCategory}
            />
          </S.Wrapper>

          <S.Wrapper>
            <ServiceName
              disabled={
                isEditService && popStackNavigation() === "detailEquipment"
              }
              name="service"
              hasErrorOnService={hasErrorOnService}
            />
          </S.Wrapper>

          <S.Wrapper>
            <Dropdown
              disabled={
                isEditService && popStackNavigation() === "detailEquipment"
              }
              label="Periodicidade do serviço*"
              placeholder="Selecione uma periodicidade"
              options={DROPDOWN_OPTIONS}
              categoryIdSelected={frequencyIdDropdown.id}
              // eslint-disable-next-line react/jsx-no-bind
              onClickedValue={handleSelectedFrequency}
              hasError={hasErrorOnFrequency}
            />
          </S.Wrapper>

          <S.Wrapper>
            <S.Row>
              <S.WrapperInput>
                <span> Tempo de execução (opcional) </span>
                <div
                  style={{
                    marginBottom:
                      hasErrorOnRuntimeExtension.length > 0 ? 18 : 0,
                  }}
                >
                  <Input
                    type="number"
                    placeholder="Ex: 2"
                    onChange={(text) =>
                      handleSetRunTime(text.currentTarget.value)
                    }
                    value={runTime}
                    hasError={hasErrorOnRunTime}
                    onFocus={() => {
                      setHasErrorOnRunTime("");
                    }}
                  />
                </div>
              </S.WrapperInput>

              <S.WrapperDrodown>
                <div
                  style={{
                    marginBottom: hasErrorOnRunTime.length > 0 ? 18 : 0,
                  }}
                >
                  <Dropdown
                    placeholder="Selecione"
                    options={runTimeExtensionOptions}
                    categoryIdSelected={runTimeExtension.id}
                    onClickedValue={handleSelectRuntimeExtension}
                    hasError={hasErrorOnRuntimeExtension}
                    clearDropDownItem
                    handleClear={handleClearRunTimeExtension}
                  />
                </div>
              </S.WrapperDrodown>
            </S.Row>
          </S.Wrapper>

          <S.Wrapper>
            <S.Row>
              <S.WrapperInput>
                <div
                  style={{
                    marginBottom:
                      hasErrorOnWarrantyExtension.length > 0 ? 18 : 0,
                  }}
                >
                  <span> Garantia (opcional)</span>
                  <Input
                    type="number"
                    placeholder="Ex: 180"
                    onChange={(text) =>
                      handleSetWarrantyTime(text.currentTarget.value)
                    }
                    value={warrantyTime}
                    hasError={hasErrorOnWarrantyTime}
                    onFocus={() => {
                      setHasErrorOnWarrantyTime("");
                    }}
                  />
                </div>
              </S.WrapperInput>

              <S.WrapperDrodown>
                <div
                  style={{
                    marginBottom: hasErrorOnWarrantyTime.length > 0 ? 18 : 0,
                  }}
                >
                  <Dropdown
                    placeholder="Selecione"
                    options={warrantyExtensionOptions}
                    categoryIdSelected={warrantyExtension.id}
                    // eslint-disable-next-line react/jsx-no-bind
                    onClickedValue={handleWarrantyExtension}
                    hasError={hasErrorOnWarrantyExtension}
                    clearDropDownItem
                    // eslint-disable-next-line react/jsx-no-bind
                    handleClear={handleClearWarrantyExtension}
                  />
                </div>
              </S.WrapperDrodown>
            </S.Row>
          </S.Wrapper>

          <S.Wrapper>
            <CommentsTextArea
              placeholder="Descrição da execução do serviço."
              label="Descreva etapas do serviço (opcional)"
              value={observationText}
              onChange={(event) => handleSetObservationText(event.target.value)}
              hasError={hasErrorOnObservationText}
            />
          </S.Wrapper>

          <S.Wrapper>
            <Input
              name="Valor (opcional)"
              maskType="money"
              valueCurrency={priceService}
              onChangeCurrency={({ formattedValue, floatValue }) => {
                handleSetPriceService(formattedValue);
                handleSetUnmaskedPrice(floatValue!);
              }}
              hasError={hasErrorOnPrice}
              onFocusClearError={() => {
                setHasErrorOnPrice("");
              }}
            />
          </S.Wrapper>

          {selectedItemChecklist.length > 0 && (
            <S.Wrapper>
              {selectedItemChecklist.map((currentChecklist) => {
                return (
                  <S.WrapperService key={currentChecklist.id}>
                    <CardChecklist>
                      <S.WrapperInfoCard>
                        <S.WrapperData>
                          <h6>{currentChecklist.name}</h6>
                          <p>{currentChecklist?.registers?.length} Registros</p>
                        </S.WrapperData>
                        <S.WrapperButtom>
                          <EditButton
                            disabled={
                              currentChecklist.default ||
                              (isEditService &&
                                popStackNavigation() === "detailEquipment")
                            }
                            onClick={() => {
                              handleSetEditPage(true);
                              detailItem(currentChecklist);
                            }}
                          />
                          <DeleteButton
                            disabled={
                              currentChecklist.default ||
                              (isEditService &&
                                popStackNavigation() === "detailEquipment")
                                ? true
                                : false
                            }
                            onClick={() => {
                              setIndexDelete(Number(currentChecklist.id));
                              setIsOpenAlertModalDelete(true);
                            }}
                          />
                        </S.WrapperButtom>
                      </S.WrapperInfoCard>
                    </CardChecklist>
                  </S.WrapperService>
                );
              })}
            </S.Wrapper>
          )}

          {popStackNavigation() !== "detailEquipment" && (
            <S.Wrapper>
              <Button typeButton="outline" onClick={() => handleAddChecklist()}>
                Incluir checklists
              </Button>
            </S.Wrapper>
          )}

          <S.Wrapper>
            <Button
              onClick={() => {
                SaveInfoServices();
              }}
            >
              Salvar
            </Button>
          </S.Wrapper>
        </S.Container>
      )}
    </>
  );
}
