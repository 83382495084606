import { useState, useEffect } from "react";

import axios from "axios";
import * as Yup from "yup";
import { isEqual } from "lodash";
import { format } from "date-fns";
import { useHistory, useParams } from "react-router";
import { useTheme } from "styled-components";

import CalenderWhiteSvg from "assets/icons/calender-white.svg";
import CalenderGreenSvg from "assets/icons/calender-green.svg";
import AcceptIconSvg from "assets/icons/accept-not-background.svg";
import filePdfSvg from "assets/icons/file-pdf.svg";

import { useAuth } from "hooks/auth";
import { useToast } from "hooks/toast";
import { useAccount } from "hooks/permission/account";

import { api } from "services/api";

import getValidationErrors from "utils/getValidationErrors";
import { containsEscapeCharacters } from "utils/containsEscapeCharacters ";

import { Input } from "components/Input";
import { Button } from "components/Button";
import { Calendar } from "components/Calendar";
import { ModalRight } from "components/ModalRight";
import { ArrowButton } from "components/ArrowButton";
import { NewAlertModal } from "components/NewAlertModal";
import { LoadingProfiz } from "components/LoadingProfiz";
import { CommentsTextArea } from "components/CommentsTextArea";
import { ListItemCard } from "components/Permission/ListItemCard";
import { ServiceRegisterPhoto } from "components/ServiceRegisterPhoto";

import * as S from "./styles";

import { PhotoCardStep } from "../PhotoCardStep";
import { AddSignaturePhoto } from "../AddSignaturePhoto";

type Photo = {
  id?: number;
  link?: string;
  type?: string;
};

type Errors = {
  [key: string]: string;
};

type FileImageProps = {
  base64Image: string;
  extension: string;
};

type RouteParams = {
  idReceipt: string;
};

type ReceiptResponse = {
  id: number;
  receiptNumber: number;
  idProfizAppClient: number;
  clientName: string;
  description: string;
  amountPaid: number;
  assignedValue: string;
  paymentMethod: string;
  additionalInformation: string;
  observation: string;
  detailLink: string;
  receiptAt: string;
  createdAt: string;
  subscriptionProvider: {
    fileId: number;
    link: string;
  };
  subscriptionCustomer: {
    fileId: number;
    link: string;
  };
};

type BackupReceipt = {
  clientName: string;
  description: string;
  amountPaid: number;
  assignedValue: string;
  paymentMethod: string;
  selectedDateReceipt: string;
  additionalInformation: string;
  observation: string;
  providerSignaturePicture:
    | {
        id: number;
        link: string;
      }
    | {};
  clientSignaturePicture:
    | {
        id: number;
        link: string;
      }
    | {};
};

function EditReceipt() {
  const theme = useTheme();
  const history = useHistory();
  const { idReceipt } = useParams<RouteParams>();

  const { user } = useAuth();
  const { whoami } = useAccount();
  const { addToast } = useToast();

  const [clientName, setClientName] = useState("");
  const [description, setDescription] = useState("");
  const [amountPaid, setAmountPaid] = useState(0);
  const [assignedValue, setAssignedValue] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("");
  const [selectedDateReceipt, setSelectedDateReceipt] = useState<
    Date | undefined
  >(undefined);
  const [additionalInformation, setAdditionalInformation] = useState("");
  const [observation, setObservation] = useState("");
  const [providerSignaturePicture, setProviderSignaturePicture] =
    useState<Photo>({} as Photo);
  const [clientSignaturePicture, setClientSignaturePicture] = useState<Photo>(
    {} as Photo
  );
  const [openModalCalendar, setOpenModalCalendar] = useState(false);
  const [hasError, setHasError] = useState<Errors>({} as Errors);
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
  const [isLoadingPhotoSignature, setIsLoadingPhotoSignature] = useState(false);
  const [isLoadingPhotoClient, setIsLoadingPhotoClient] = useState(false);
  const [loadingPhotoId, setLoadingPhotoId] = useState(0);
  const [openModalAlertBack, setOpenModalAlertBack] = useState(false);
  const [isLoadingPage, setIsLoadingPage] = useState(false);
  const [backupReceipt, setBackupReceipt] = useState<BackupReceipt>(
    {} as BackupReceipt
  );
  const [createdAt, setCreatedAt] = useState("");
  const [receiptNumber, setReceiptNumber] = useState(0);
  const [hasErrorOnDescription, setHasErrorOnDescription] = useState("");
  const [hasErrorOnAdditionalInformation, setHasErrorOnAdditionalInformation] =
    useState("");
  const [hasErrorOnObservation, setHasErrorOnObservation] = useState("");

  const receiptObj = {
    clientName,
    description,
    amountPaid,
    assignedValue,
    paymentMethod,
    selectedDateReceipt: selectedDateReceipt
      ? format(selectedDateReceipt!, "yyyy-MM-dd HH:mm:ss")
      : undefined,
    additionalInformation,
    observation,
    providerSignaturePicture,
    clientSignaturePicture,
  };

  useEffect(() => {
    if (!whoami?.isMe) history.push("/home");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [whoami]);

  const fetchReceipts = async () => {
    try {
      setIsLoadingPage(true);
      const { data } = await api.get<ReceiptResponse>(`receipt/${idReceipt}`);

      setBackupReceipt({
        clientName: data?.clientName,
        description: data?.description,
        amountPaid: data?.amountPaid,
        assignedValue: data?.assignedValue,
        paymentMethod: data?.paymentMethod,
        selectedDateReceipt: data?.receiptAt,
        additionalInformation: data?.additionalInformation,
        observation: data?.observation,
        providerSignaturePicture: data?.subscriptionProvider?.link
          ? {
              id:
                data?.subscriptionProvider?.fileId > 0
                  ? data?.subscriptionProvider?.fileId
                  : -1,
              link: data?.subscriptionProvider?.link,
            }
          : {},
        clientSignaturePicture: data?.subscriptionCustomer?.link
          ? {
              id: data?.subscriptionCustomer?.fileId,
              link: data?.subscriptionCustomer?.link,
            }
          : {},
      });

      if (data?.clientName) setClientName(data?.clientName);
      if (data?.description) setDescription(data?.description);
      if (data?.amountPaid) setAmountPaid(data?.amountPaid);
      if (data?.assignedValue) setAssignedValue(data?.assignedValue);
      if (data?.paymentMethod) setPaymentMethod(data?.paymentMethod);
      if (data?.receiptAt) setSelectedDateReceipt(new Date(data?.receiptAt));
      if (data?.additionalInformation)
        setAdditionalInformation(data?.additionalInformation);
      if (data?.observation) setObservation(data?.observation);
      if (data?.createdAt) setCreatedAt(data?.createdAt);
      if (data?.receiptNumber) setReceiptNumber(data?.receiptNumber);
      if (data?.subscriptionProvider?.link) {
        setProviderSignaturePicture({
          id:
            data?.subscriptionProvider?.fileId > 0
              ? data?.subscriptionProvider?.fileId
              : -1,
          link: data?.subscriptionProvider?.link,
        });
      }
      if (data?.subscriptionCustomer?.link) {
        setClientSignaturePicture({
          id: data?.subscriptionCustomer?.fileId,
          link: data?.subscriptionCustomer?.link,
        });
      }
    } catch (error) {
      addToast({
        title: "Erro!",
        description: "Não foi possível carregar as informações do recibo.",
        type: "error",
      });
    } finally {
      setIsLoadingPage(false);
    }
  };

  useEffect(() => {
    fetchReceipts();
  }, [idReceipt]); // eslint-disable-line

  async function handleSubmit(openViewReceipt: boolean) {
    try {
      setIsLoadingSubmit(true);

      const schema = () => {
        return Yup.object().shape({
          clientName: Yup.string().trim().required("Cliente é obrigatório"),
          description: Yup.string()
            .trim()
            .required("Descrição do recibo é obrigatório"),
          amountPaid: Yup.number().min(0.01, "Valor pago é obrigatório"),
          selectedDateReceipt: Yup.string()
            .trim()
            .required("Data do recebimento é obrigatório"),
        });
      };

      await schema().validate(
        {
          clientName,
          description,
          amountPaid,
          selectedDateReceipt,
        },
        { abortEarly: false }
      );

      if (containsEscapeCharacters(description)) {
        setHasErrorOnDescription('Não é permitido uso de barra invertida "\\"');
        addToast({
          title: "Ops!!",
          description: 'Não é permitido uso de barra invertida "\\"',
          type: "error",
        });

        return;
      } else {
        setHasErrorOnDescription("");
      }

      if (containsEscapeCharacters(additionalInformation)) {
        setHasErrorOnAdditionalInformation(
          'Não é permitido uso de barra invertida "\\"'
        );
        addToast({
          title: "Ops!!",
          description: 'Não é permitido uso de barra invertida "\\"',
          type: "error",
        });

        return;
      } else {
        setHasErrorOnAdditionalInformation("");
      }

      if (containsEscapeCharacters(observation)) {
        setHasErrorOnObservation('Não é permitido uso de barra invertida "\\"');
        addToast({
          title: "Ops!!",
          description: 'Não é permitido uso de barra invertida "\\"',
          type: "error",
        });

        return;
      } else {
        setHasErrorOnObservation("");
      }

      setHasError({});

      if (
        "id" in backupReceipt?.providerSignaturePicture &&
        backupReceipt?.providerSignaturePicture?.id !== -1 &&
        backupReceipt?.providerSignaturePicture.id !==
          providerSignaturePicture?.id
      ) {
        await api.delete(
          `upload/file/${backupReceipt.providerSignaturePicture?.id}`
        );
      }

      if (
        "id" in backupReceipt?.clientSignaturePicture &&
        backupReceipt?.clientSignaturePicture?.id !== clientSignaturePicture?.id
      ) {
        await api.delete(
          `upload/file/${backupReceipt.clientSignaturePicture?.id}`
        );
      }

      const receipt = {
        clientName,
        description,
        amountPaid,
        assignedValue,
        paymentMethod,
        additionalInformation,
        observation,
        subscriptionProvider:
          providerSignaturePicture?.id! > 0
            ? providerSignaturePicture?.id
            : providerSignaturePicture?.link
            ? providerSignaturePicture?.link
            : "",
        subscriptionCustomer:
          clientSignaturePicture?.id! > 0
            ? clientSignaturePicture?.id
            : clientSignaturePicture?.link
            ? clientSignaturePicture?.link
            : "",
        receiptAt: format(selectedDateReceipt!, "yyyy-MM-dd"),
      };

      await api.put(`receipt/${idReceipt}`, receipt);
      await fetchReceipts();

      addToast({
        title: "Alterações salvas!",
        description: "Alterações realizadas no recibo salvas com sucesso.",
        type: "success",
      });

      if (openViewReceipt) {
        history.push(`/financial/edit-receipt/${idReceipt}/preview`);
      }
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);
        setHasError(errors);

        addToast({
          title: "Ops...",
          description: "Preencha todos os campos obrigatórios",
          type: "error",
        });

        return;
      }

      addToast({
        title: "Falha ao salvar alterações.",
        description: "Não foi possível salvar as alterações, tente novamente",
        type: "error",
      });
    } finally {
      setIsLoadingSubmit(false);
    }
  }

  async function handleUploadPhoto(
    photo: FileImageProps,
    subscriptionOwner: "provider" | "client"
  ) {
    try {
      subscriptionOwner === "provider"
        ? setIsLoadingPhotoSignature(true)
        : setIsLoadingPhotoClient(true);

      const { data } = await api.post("upload/image", {
        grupo: `receipt/${user?.indication?.hash}`,
        encode: photo.base64Image,
        extensao: photo.extension,
      });

      subscriptionOwner === "provider"
        ? setProviderSignaturePicture({
            id: data?.id,
            link: data?.url,
          })
        : setClientSignaturePicture({
            id: data?.id,
            link: data?.url,
          });

      addToast({
        title: "Sucesso",
        description: "Foto adicionada com sucesso.",
        type: "success",
      });
    } catch (error) {
      addToast({
        title: "Ops!!",
        description:
          axios.isAxiosError(error) && error?.response?.data?.error
            ? error?.response?.data?.error
            : "Não foi possível adicionar a foto.",
        type: "error",
      });
    } finally {
      subscriptionOwner === "provider"
        ? setIsLoadingPhotoSignature(false)
        : setIsLoadingPhotoClient(false);
    }
  }

  async function handleDeletePhoto(
    photoId: number,
    subscriptionOwner: "provider" | "client"
  ) {
    try {
      setLoadingPhotoId(photoId);

      if (subscriptionOwner === "provider") {
        if (
          !("id" in backupReceipt?.providerSignaturePicture) &&
          providerSignaturePicture?.id !== -1
        ) {
          await api.delete(`upload/file/${photoId}`);
        }

        setProviderSignaturePicture({});
      } else {
        if (!("id" in backupReceipt?.clientSignaturePicture)) {
          await api.delete(`upload/file/${photoId}`);
        }

        setClientSignaturePicture({});
      }
    } catch (error) {
      addToast({
        title: "Ops!!",
        description:
          axios.isAxiosError(error) && error?.response?.data?.error
            ? error?.response?.data?.error
            : "Não foi possível remover a foto.",
        type: "error",
      });
    } finally {
      setLoadingPhotoId(0);
    }
  }

  function handleBack() {
    const hasEqual = isEqual(backupReceipt, receiptObj);

    if (hasEqual) {
      history.goBack();
    } else {
      setOpenModalAlertBack(true);
    }
  }

  if (isLoadingPage || isLoadingSubmit) {
    return <LoadingProfiz isVisible={isLoadingPage || isLoadingSubmit} />;
  }

  return (
    <>
      <S.Container>
        <S.Header>
          <ArrowButton handleFunction={handleBack} />
        </S.Header>

        <S.Content>
          <S.Title>Recibo {receiptNumber}</S.Title>

          <S.SubTitle>
            Data de criação:{" "}
            {createdAt && format(new Date(createdAt), "dd/MM/yyyy")}
          </S.SubTitle>

          <S.TextInfoSub>Dados gerais</S.TextInfoSub>

          <S.Form>
            <S.WrapperInputs>
              <Input
                name="Cliente*"
                placeholder="Informe o nome do cliente"
                value={clientName}
                onChange={(e) => setClientName(e.target.value.trimStart())}
                hasError={hasError.clientName}
                onFocusClearError={() =>
                  setHasError({ ...hasError, clientName: "" })
                }
              />
            </S.WrapperInputs>

            <CommentsTextArea
              label="Descrição do recibo*"
              placeholder="Ex: Recibo refertente a"
              value={description}
              onChange={(e) => setDescription(e.target.value.trimStart())}
              hasError={hasError.description || hasErrorOnDescription}
              onFocusClearError={() =>
                setHasError({ ...hasError, description: "" })
              }
            />

            <S.WrapperInputs>
              <S.LabelInput>Empresa</S.LabelInput>
              <ListItemCard
                style={{ height: 50 }}
                image={whoami?.photo || ""}
                roundedImage
                title={whoami?.name || ""}
              />
            </S.WrapperInputs>

            <S.TextInfoSub>Informações de pagamento</S.TextInfoSub>

            <S.WrapperInputs>
              <Input
                maskType="money"
                name="Valor pago*"
                value={amountPaid}
                valueCurrency={amountPaid}
                onChangeCurrency={({ floatValue }) => {
                  setAmountPaid(floatValue || 0);
                }}
                hasError={hasError.amountPaid}
                onFocusClearError={() =>
                  setHasError({ ...hasError, amountPaid: "" })
                }
              />
            </S.WrapperInputs>

            <S.WrapperInputs>
              <Input
                name="Este valor refere-se a"
                placeholder="Ex: 'primeira parcela', 'parcela 1/2', 'sinal de 50%'."
                value={assignedValue}
                onChange={(e) => setAssignedValue(e.target.value.trimStart())}
                hasError={hasError.assignedValue}
                onFocusClearError={() =>
                  setHasError({ ...hasError, assignedValue: "" })
                }
              />
            </S.WrapperInputs>

            <S.WrapperInputs>
              <Input
                name="Forma de pagamento"
                placeholder="Defina a forma de pagamento"
                value={paymentMethod}
                onChange={(e) => setPaymentMethod(e.target.value.trimStart())}
                hasError={hasError.paymentMethod}
                onFocusClearError={() =>
                  setHasError({ ...hasError, paymentMethod: "" })
                }
              />
            </S.WrapperInputs>

            <S.WrapperInputs>
              <S.ContainerButton>
                <S.LabelButton>Data do recebimento*</S.LabelButton>

                <S.ButtonOpenModal
                  hasValue={!!selectedDateReceipt}
                  hasError={!!hasError.selectedDateReceipt}
                  onClick={() => {
                    setHasError({ ...hasError, selectedDateReceipt: "" });
                    setOpenModalCalendar(true);
                  }}
                >
                  <S.CheckContainer>
                    <img
                      src={
                        !!selectedDateReceipt
                          ? CalenderGreenSvg
                          : CalenderWhiteSvg
                      }
                      alt="Ícone de calendário"
                    />

                    <S.ButtonOpenModalText hasValue={!!selectedDateReceipt}>
                      {!!selectedDateReceipt
                        ? format(selectedDateReceipt, "dd/MM/yyyy")
                        : "Selecione uma data"}
                    </S.ButtonOpenModalText>
                  </S.CheckContainer>
                </S.ButtonOpenModal>

                {!!hasError.selectedDateReceipt && (
                  <S.ErrorText>{hasError.selectedDateReceipt}</S.ErrorText>
                )}
              </S.ContainerButton>
            </S.WrapperInputs>

            <S.WrapperInputs>
              <CommentsTextArea
                label="Informações adicionais"
                placeholder="Texto livre."
                value={additionalInformation}
                onChange={(e) =>
                  setAdditionalInformation(e.target.value.trimStart())
                }
                hasError={
                  hasError.additionalInformation ||
                  hasErrorOnAdditionalInformation
                }
                onFocusClearError={() =>
                  setHasError({ ...hasError, additionalInformation: "" })
                }
              />
            </S.WrapperInputs>

            <S.WrapperInputs>
              <CommentsTextArea
                label="Observações"
                placeholder="Texto livre."
                value={observation}
                onChange={(e) => setObservation(e.target.value.trimStart())}
                hasError={hasError.observation || hasErrorOnObservation}
                onFocusClearError={() =>
                  setHasError({ ...hasError, observation: "" })
                }
              />
            </S.WrapperInputs>

            <S.TextInfo>
              As observações não ficarão visíveis no recibo.
            </S.TextInfo>

            <S.WrapperInputs>
              <S.LabelInput>Assinaturas</S.LabelInput>
            </S.WrapperInputs>

            <S.WrapperInputs>
              <PhotoCardStep
                title="Adicione sua assinatura."
                maxPhotos={1}
                photosQuantity={
                  Object.entries(providerSignaturePicture).length - 1
                }
              >
                <S.ContentImage>
                  <S.WrapperImage>
                    <AddSignaturePhoto
                      type="signature"
                      loading={isLoadingPhotoSignature}
                      maxPhotos={1}
                      photosQuantity={
                        Object.entries(providerSignaturePicture).length
                      }
                      handleSetProviderSignaturePicture={(url) =>
                        setProviderSignaturePicture({ id: -1, link: url })
                      }
                      handleAddNewPhoto={(photo) =>
                        handleUploadPhoto(photo, "provider")
                      }
                    />
                  </S.WrapperImage>
                  {Object.entries(providerSignaturePicture).length > 0 && (
                    <S.WrapperImage key={providerSignaturePicture?.id}>
                      <ServiceRegisterPhoto
                        loading={
                          loadingPhotoId === providerSignaturePicture?.id
                        }
                        key={providerSignaturePicture?.id}
                        url={providerSignaturePicture?.link!}
                        onDelete={() =>
                          handleDeletePhoto(
                            providerSignaturePicture?.id!,
                            "provider"
                          )
                        }
                      />
                    </S.WrapperImage>
                  )}
                </S.ContentImage>
              </PhotoCardStep>
            </S.WrapperInputs>

            <S.WrapperInputs>
              <PhotoCardStep
                title="Adicione a assinatura do cliente."
                maxPhotos={1}
                photosQuantity={
                  Object.entries(clientSignaturePicture).length - 1
                }
              >
                <S.ContentImage>
                  <S.WrapperImage>
                    <AddSignaturePhoto
                      type="photo"
                      loading={isLoadingPhotoClient}
                      maxPhotos={1}
                      photosQuantity={
                        Object.entries(clientSignaturePicture).length
                      }
                      handleAddNewPhoto={(photo) =>
                        handleUploadPhoto(photo, "client")
                      }
                    />
                  </S.WrapperImage>

                  {Object.entries(clientSignaturePicture).length > 0 && (
                    <S.WrapperImage key={clientSignaturePicture.id}>
                      <ServiceRegisterPhoto
                        loading={loadingPhotoId === clientSignaturePicture?.id}
                        key={clientSignaturePicture?.id}
                        url={clientSignaturePicture?.link!}
                        onDelete={() =>
                          handleDeletePhoto(
                            clientSignaturePicture?.id!,
                            "client"
                          )
                        }
                      />
                    </S.WrapperImage>
                  )}
                </S.ContentImage>
              </PhotoCardStep>
            </S.WrapperInputs>

            <Button
              onClick={() => handleSubmit(false)}
              style={{ fontWeight: 700 }}
            >
              Salvar alterações
            </Button>

            <Button
              onClick={() => handleSubmit(true)}
              style={{ fontWeight: 400 }}
              typeButton="outline"
            >
              <img
                src={filePdfSvg}
                alt="Ícone de arquivo"
                height={20}
                width={20}
              />
              Visualizar recibo
            </Button>
          </S.Form>
        </S.Content>
      </S.Container>

      <ModalRight
        isOpen={openModalCalendar}
        handleToggleOpen={() => setOpenModalCalendar(false)}
      >
        <ArrowButton handleFunction={() => setOpenModalCalendar(false)} />

        <S.TitleModal>Selecione a data</S.TitleModal>

        <S.WrapperCalendar>
          <Calendar
            selectDays={selectedDateReceipt}
            onChangeDate={(day) => setSelectedDateReceipt(day)}
          />
        </S.WrapperCalendar>

        <S.WrapperInputs>
          <S.ContainerButton>
            <S.LabelButton>Data do recebimento</S.LabelButton>

            <S.ButtonOpenModal hasValue={!!selectedDateReceipt}>
              <S.CheckContainer>
                <img
                  src={
                    !!selectedDateReceipt ? CalenderGreenSvg : CalenderWhiteSvg
                  }
                  alt="Ícone de calendário"
                />

                <S.ButtonOpenModalText hasValue={!!selectedDateReceipt}>
                  {!!selectedDateReceipt
                    ? format(selectedDateReceipt, "dd/MM/yyyy")
                    : "Selecione uma data"}
                </S.ButtonOpenModalText>
              </S.CheckContainer>
              {!!selectedDateReceipt && (
                <button
                  onClick={() => {
                    setOpenModalCalendar(false);
                  }}
                >
                  <img src={AcceptIconSvg} alt="Concluído" />
                </button>
              )}
            </S.ButtonOpenModal>
          </S.ContainerButton>
        </S.WrapperInputs>
      </ModalRight>

      <NewAlertModal
        isVisible={openModalAlertBack}
        title="Deseja sair da edição do recibo?"
        description="Ao sair sem finalizar suas informações serão perdidas."
        action="choose"
        handleConfirm={() => setOpenModalAlertBack(false)}
        onCloseModal={() => history.goBack()}
        labelConfirm="Continuar editando"
        labelCancel="Descartar informações"
        buttonConfirmColor={theme.colors.primary}
      />
    </>
  );
}

export { EditReceipt };
