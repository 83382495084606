import styled, { css } from "styled-components";
import { generateMedia } from "styled-media-query";

const customMedia = generateMedia({
  medium: "700px",
  small: "368px",
  xsmall: "294px",
});

export const BackButton = styled.button`
  ${({ theme }) => css`
    width: 35px;
    height: 35px;
    background-color: ${theme.colors.background_light};
    border-radius: 10px;

    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 25px;
    margin-bottom: 20px;
  `}
`;

export const Container = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.background_modal};
    overflow: auto;
    height: 80vh;
    margin-left: 15%;
    margin-right: 15%;
    margin-top: 30px;
    padding: 15px;
    border-radius: 10px;

    ${customMedia.lessThan("medium")`
      ${css`
        margin-left: 5%;
        margin-right: 5%;
      `}
    `}
  `}
`;

export const Description = styled.p`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.medium};
    color: ${theme.colors.text};
    margin: 0 8px;
    text-align: justify;
    display: flex;
    flex-direction: column;
    margin-right: 20px;
    margin-bottom: 30px;
  `}
`;

export const Title = styled.h1`
  ${({ theme }) => css`
    margin-top: 30px;
    font-size: ${theme.font.sizes.large};
    color: ${theme.colors.text_extra_light};
    margin-bottom: 12px;
  `}
`;
