const containsEscapeCharacters = (text: string) => {
  if (text) {
    const escapeCharactersRegex = /\\/g;

    return escapeCharactersRegex.test(text);
  }

  return false;
};

export { containsEscapeCharacters };
