import {
  ChangeEvent,
  FormEvent,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useTheme } from "styled-components";
import { FiChevronLeft } from "react-icons/fi";

import { useToast } from "hooks/toast";
import { useEditClient } from "hooks/editClient";
import { useClient } from "hooks/budget/client";

import { getCep } from "services/cep";
import { states } from "./states";

import { Input } from "components/Input";
import { CommentsTextArea } from "components/CommentsTextArea";
import { Button } from "components/Button";
import { Dropdown } from "components/DropDown";
import { AlertModal } from "components/AlertModal";
import { AlertModalCheckbox } from "components/AlertModalCheckbox";
import { LoadingProfiz } from "components/LoadingProfiz";
import { ModalPageProps } from "components/ModalPageClients/types";

import * as S from "./styles";
import { api } from "services/api";

import { UnityDTO } from "dtos/ClientDTO";
import { mappedUnity } from "./tools";
import getValidationErrors from "utils/getValidationErrors";
import { containsEscapeCharacters } from "utils/containsEscapeCharacters ";
import { isEqual } from "lodash";

import * as Yup from "yup";
import { useConfirmModalClient } from "hooks/budget/confirmModalClient";
import { UnitiesResponse } from "components/UnityAreaComponent";
import { ShowWhenHavePermission } from "components/Permission";

interface ValidationProps {
  submiting?: boolean;
}

type TViaCepProps = {
  cep: string;
  logradouro: string;
  complemento?: string;
  bairro: string;
  localidade: string;
  uf: string;
  ibge: string;
  gia?: string;
  ddd?: string;
  siafi?: string;
  erro?: string;
};

type PageProps = {
  handleChooseEditModal?: ModalPageProps;
  handlePmocEditModal?: (unity?: UnityDTO) => void;
  handleToggleOpen?: () => void;
  defaultUnity?: boolean;
  handleFunction?: () => void;
  module?: string;
  getFilteredUnities?: (unities: UnityDTO[]) => void;
  modulePmoc?: boolean;
  isNewClient?: boolean;
};

type Errors = {
  [key: string]: string;
};

export function UnityForm({
  handleChooseEditModal,
  handlePmocEditModal,
  handleToggleOpen,
  defaultUnity,
  handleFunction,
  module,
  getFilteredUnities,
  modulePmoc = false,
  isNewClient = false,
}: PageProps) {
  const {
    client,
    handleSetClient,
    handleSetUnityClient,
    hasUnity,
    unity,
    getClient,
  } = useClient();
  const { handleChangeModalProps, setConfirmModalProps, setHasChangeds } =
    useConfirmModalClient();

  const {
    selectedUnity,
    setSelectedUnity,
    editedClient,
    handleSetEditClient,
    setUnities,
    unities,
    redefineClientUnity,
  } = useEditClient();
  const [isVisibleAlert, setIsVisibleAlert] = useState(false);
  const [visibleAlertState, setVisibleAlertState] = useState(1);
  const [checked, setChecked] = useState([false, false, false]);
  const [openModalAlert, setOpenModalAlert] = useState(false);

  const [unityName, setUnityName] = useState("");
  const [responsibleUnity, setResponsibleUnity] = useState("");
  const [postalCodeUnity, setPostalCodeUnity] = useState("");
  const [streetUnity, setStreetUnity] = useState("");
  const [numberUnity, setNumberUnity] = useState("");
  const [districtUnity, setDistrictUnity] = useState("");
  const [cityUnity, setCityUnity] = useState("");
  const [stateUnity, setStateUnity] = useState("");
  const [complementUnity, setComplementUnity] = useState("");
  const [additionalInfoUnity, setAdditionalInfoUnity] = useState("");

  const [loadingButton, setLoadingButton] = useState(false);

  const [loadingPage, setLoadingPage] = useState(false);

  const [hasError, setHasError] = useState<Errors>({} as Errors);
  const [hasErrorOnAdditionalInfoUnity, setHasErrorOnAdditionalInfoUnity] =
    useState("");

  const { addToast } = useToast();
  const theme = useTheme();

  const setInfoUnity = useCallback(() => {
    try {
      setLoadingPage(true);
      selectedUnity.name && setUnityName(selectedUnity.name);
      selectedUnity.responsible &&
        setResponsibleUnity(selectedUnity.responsible);
      selectedUnity.postalCode && setPostalCodeUnity(selectedUnity.postalCode);
      selectedUnity.street && setStreetUnity(selectedUnity.street);
      selectedUnity.district && setDistrictUnity(selectedUnity.district);
      selectedUnity.number && setNumberUnity(selectedUnity.number);
      selectedUnity.city && setCityUnity(selectedUnity.city);
      selectedUnity.uf && setStateUnity(selectedUnity.uf);
      selectedUnity.complement && setComplementUnity(selectedUnity.complement);
      selectedUnity.additionalInfo &&
        setAdditionalInfoUnity(selectedUnity.additionalInfo);
    } catch {
      addToast({
        type: "error",
        title: "Ops !!",
        description: "Ocorreu um erro ao carregar os dados.",
      });
    } finally {
      setLoadingPage(false);
    }
  }, [
    addToast,
    selectedUnity.additionalInfo,
    selectedUnity.city,
    selectedUnity.complement,
    selectedUnity.district,
    selectedUnity.name,
    selectedUnity.number,
    selectedUnity.postalCode,
    selectedUnity.responsible,
    selectedUnity.street,
    selectedUnity.uf,
  ]);

  useEffect(() => {
    if (selectedUnity) {
      setInfoUnity();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUnity, selectedUnity.id, setInfoUnity]);

  useEffect(() => {
    if (defaultUnity) {
      setResponsibleUnity(editedClient.name);
      setSelectedUnity({
        ...selectedUnity,
        email: editedClient.email || "",
        phone: editedClient.phone || "",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultUnity]);

  const handleInputChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    name: string
  ) => {
    const { value } = event.target;
    setSelectedUnity({ ...selectedUnity, [name]: value.trimStart() });
  };

  const handleCheckboxCheck = (position: number) => {
    checked[position] = !checked[position];

    setChecked([...checked]);

    if (checked[position]) {
      if (position === 0) {
        setResponsibleUnity(editedClient.name);
      } else if (position === 1) {
        setSelectedUnity({ ...selectedUnity, email: editedClient.email ?? "" });
      } else if (position === 2) {
        setSelectedUnity({ ...selectedUnity, phone: editedClient.phone ?? "" });
      } else {
        setSelectedUnity({
          ...selectedUnity,
          postalCode: editedClient?.address?.postalCode ?? "",
        });
        setPostalCodeUnity(editedClient?.address?.postalCode ?? "");
      }
    } else {
      if (position === 0) {
        setResponsibleUnity("");
      } else if (position === 1) {
        setSelectedUnity({ ...selectedUnity, email: "" });
      } else if (position === 2) {
        setSelectedUnity({ ...selectedUnity, phone: "" });
      } else {
        setSelectedUnity({ ...selectedUnity, postalCode: "" });
        setPostalCodeUnity("");
      }
    }
  };

  const ValidateInfos = async ({ submiting = true }: ValidationProps) => {
    try {
      setLoadingButton(true);

      const schema = () => {
        return Yup.object().shape({
          unityName: Yup.string().required("Nome é obrigatório!"),
          postalCodeUnity: Yup.string().required("CEP é obrigatório!"),
          districtUnity: Yup.string().required("Nome do bairro é obrigatório!"),
          streetUnity: Yup.string().required("Nome da rua é obrigatório!"),
          numberUnity: Yup.string().required("Número é obrigatório!"),
          cityUnity: Yup.string().required("Cidade é obrigatório!"),
          stateUnity: Yup.string().required("Estado é obrigatório!"),
        });
      };
      await schema().validate(
        {
          unityName,
          postalCodeUnity,
          districtUnity,
          streetUnity,
          numberUnity,
          cityUnity,
          stateUnity,
        },
        { abortEarly: false }
      );

      if (containsEscapeCharacters(additionalInfoUnity)) {
        setHasErrorOnAdditionalInfoUnity(
          'Não é permitido uso de barra invertida "\\"'
        );
        addToast({
          title: "Ops!!",
          description: 'Não é permitido uso de barra invertida "\\"',
          type: "error",
        });

        return true;
      } else {
        setHasErrorOnAdditionalInfoUnity("");
      }

      setHasError({});

      if (submiting) {
        redefineClientUnity();

        await handleSubmit();
      }

      return false;
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);
        setHasError(errors);
        addToast({
          title: "Ops...",
          description: "Preencha todos os campos obrigatórios",
          type: "error",
        });
        return true;
      }
    } finally {
      setLoadingButton(false);
    }
  };

  const unityInfo = mappedUnity({
    ...selectedUnity,
    name: unityName,
    responsible: responsibleUnity,
    postalCode: postalCodeUnity,
    street: streetUnity,
    district: districtUnity,
    number: numberUnity,
    city: cityUnity,
    uf: stateUnity,
    complement: complementUnity,
    additionalInfo: additionalInfoUnity,
    clientId: selectedUnity.clientId || client.id,
  });

  const createUnity = async () => {
    const { data } = await api.post("clients/unities", {
      ...unityInfo,
      clientId: editedClient.id || client.id,
      default: isNewClient ? !!client.has_address : !!!client.has_address,
    });
    unities.push(data);

    setUnities([...unities]);
    handleSetEditClient({
      ...client,
      id: editedClient.id || client.id,
      address: {
        name: data.name,
        postalCode: data.postalCode,
        responsible: data.responsible,
        email: data.email,
        phone: data.phone,
        street: data.street,
        district: data.district,
        number: data.number,
        city: data.city,
        uf: data.uf,
      },
      has_address: true,
    });
    handleSetClient({
      ...client,
      id: editedClient.id || client.id,
      address: {
        name: data.name,
        postalCode: data.postalCode,
        responsible: data.responsible,
        email: data.email,
        phone: data.phone,
        street: data.street,
        district: data.district,
        number: data.number,
        city: data.city,
        uf: data.uf,
      },
      has_address: true,
    });
    addToast({
      type: "success",
      title: "Tudo certo!",
      description: `${
        modulePmoc ? "Unidade criada" : "Endereço criado"
      } com sucesso!`,
    });
    if (handleFunction) {
      return handleFunction();
    }
    handleToggleOpen && handleToggleOpen();
    handleChooseEditModal && handleChooseEditModal("unityList");
  };

  const updateUnity = async () => {
    handleToggleOpen && handleToggleOpen();
    handleChooseEditModal && handleChooseEditModal("unityList");

    const { data } = await api.put(
      `clients/unities/${selectedUnity.id}`,
      unityInfo
    );

    unities[unities.findIndex((unity) => selectedUnity.id === unity.id)] = data;
    setUnities([...unities]);

    addToast({
      type: "success",
      title: "Tudo certo!",
      description: `${
        modulePmoc ? "Unidade atualizada" : "Endereço atualizado"
      } com sucesso!`,
    });
  };

  const handleSubmit = async () => {
    try {
      selectedUnity.id ? await updateUnity() : await createUnity();

      if (handlePmocEditModal) {
        return handlePmocEditModal(unityInfo);
      }
    } catch (error) {
      addToast({
        type: "error",
        title: "Ops !!",
        description: `Ocorreu um erro ao ${
          selectedUnity.id ? "atualizar" : "criar"
        } ${modulePmoc ? "esta unidade" : "este endereço"}.`,
      });
    }
  };

  async function handleLoadingCep() {
    try {
      const formattedCep = postalCodeUnity.replaceAll("_", "").replace("-", "");
      if (formattedCep.length < 8) return;

      setLoadingPage(true);

      const response = (await getCep(postalCodeUnity)) as TViaCepProps;

      if (response.erro) {
        addToast({
          type: "error",
          title: "CEP não encontrado",
          description: "Verifique o CEP informado",
        });

        return;
      }
      setStreetUnity(response.logradouro);
      setPostalCodeUnity(response.cep);
      setDistrictUnity(response.bairro);
      setCityUnity(response.localidade);
      setStateUnity(response.uf);

      setSelectedUnity({
        ...selectedUnity,
        district: response.bairro,
        postalCode: response.cep,
        street: response.logradouro,
        city: response.localidade,
        uf: response.uf,
      });
    } catch {
      addToast({
        type: "error",
        title: "Ops !!",
        description: "Ocorreu um erro ao pesquisar o CEP",
      });
    } finally {
      setLoadingPage(false);
    }
  }

  useEffect(() => {
    handleLoadingCep();
    // eslint-disable-next-line
  }, [postalCodeUnity]);

  function getAlertText(type: number) {
    let text = { title: "Sem texto", description: "Sem texto" };
    if (type === 1) {
      text = {
        title: `${modulePmoc ? "Unidade" : "Endereço"} principal?`,
        description: `Deseja tornar este ${
          modulePmoc ? "unidade" : "endereço"
        } como principal?`,
      };
    }
    if (type === 2) {
      text = {
        title: `Excluir ${modulePmoc ? "Unidade" : "Endereço"}?`,
        description: `Deseja realmente excluir ${
          modulePmoc ? "unidade" : "endereço"
        }?`,
      };
    }
    return text;
  }

  async function turnUnityToPrincipal() {
    try {
      await api.put(
        `clients/${editedClient.id || client.id}/unities/${
          selectedUnity.id
        }/default`
      );
      const { data } = await api.get(
        `clients?busca=${editedClient.name || client.name}`
      );

      const index = data.clients.findIndex((client: any) => client.id);
      const clientIndex = data.clients[index];

      handleSetEditClient(clientIndex);

      const response = await api.get<UnitiesResponse>(
        `/clients/${client.id}/unities`,
        {
          params: {
            limit: 50,
            offset: 0,
            client_id: client.id,
          },
        }
      );

      const orderedList = response.data.unities.sort((current, next) => {
        if (current.default && !next.default) {
          return -1;
        } else {
          return 1;
        }
      });

      setUnities(response.data.unities);

      if (unity?.id === orderedList[0].id) {
        handleSetUnityClient(orderedList[0]);
      }

      if (getFilteredUnities) getFilteredUnities(orderedList);
      addToast({
        type: "success",
        title: "Tudo certo!",
        description: `${
          modulePmoc ? "Unidade" : "Endereço"
        } transformado em principal com sucesso!`,
      });

      setIsVisibleAlert(false);

      handlePmocEditModal && handlePmocEditModal();
      handleChooseEditModal && handleChooseEditModal("clientDetails");
    } catch (error) {
      addToast({
        type: "error",
        title: "Ops !!",
        description: `Ocorreu um erro ao atualizar esse ${
          modulePmoc ? "unidade" : "endereço"
        }.`,
      });
    }
  }

  async function deleteUnity() {
    try {
      if (selectedUnity.default) {
        addToast({
          type: "error",
          title: "Ops !!",
          description: `Não é possível deletar ${
            modulePmoc ? "o unidade" : "a endereço"
          } principal.`,
        });
        return;
      }

      await api.delete(`clients/unities/${selectedUnity.id}`);

      addToast({
        type: "success",
        title: "Tudo certo!",
        description: `${
          modulePmoc ? "Unidade" : "Endereço"
        } deletado com sucesso!`,
      });
      handleChooseEditModal && handleChooseEditModal("clientDetails");
      handleToggleOpen && handleToggleOpen();
    } catch (error) {
      addToast({
        type: "error",
        title: "Ops !!",
        description: `Ocorreu um erro ao deletar ${
          modulePmoc ? "essa enidade" : "esse endereço"
        }.`,
      });
    }
  }

  function handleConfirm(): void {
    setConfirmModalProps(false);
  }

  const getObjectCompare = (type?: string) => {
    const compareObjedit = {
      ...(type === "edit" && selectedUnity),
      name: unityName,
      postalCode: postalCodeUnity,
      street: streetUnity,
      district: districtUnity,
      number: numberUnity,
      email: selectedUnity.email || "",
      phone: selectedUnity.phone || "",
      city: cityUnity,
      uf: stateUnity,
      responsibleUnity,
      complement: complementUnity,
      additionalInfo: additionalInfoUnity,
      checked,
    };

    return compareObjedit;
  };

  const hasChangeUnity = isEqual(getObjectCompare("edit"), {
    ...selectedUnity,
    district: editedClient?.address?.district,
    responsibleUnity: editedClient?.address?.responsible,
    email: editedClient?.address?.email,
    phone: editedClient?.address?.phone,
    checked: [false, false, false],
  });
  const hasCreateUnity = isEqual(getObjectCompare(), {
    city: "",
    district: "",
    name: "",
    number: "",
    postalCode: "",
    street: "",
    uf: "",
    complement: "",
    additionalInfo: "",
    responsibleUnity: "",
    email: "",
    phone: "",
    checked: [false, false, false],
  });
  useEffect(() => {
    setHasChangeds(selectedUnity.id ? hasChangeUnity : hasCreateUnity);
  }, [setHasChangeds, hasChangeUnity, selectedUnity.id, hasCreateUnity]);

  const redirectCloseModal = () => {
    setConfirmModalProps(false);

    handleChooseEditModal
      ? handleChooseEditModal(
          selectedUnity.id ? "unityDetail" : "clientDetails"
        )
      : handleToggleOpen && handleToggleOpen();
  };

  function handleCloseModal(): void {
    redirectCloseModal();
  }

  const persistDataModal = () => {
    handleChangeModalProps({
      value: true,
      handleConfirm,
      handleCloseModal,
    });
  };

  function handleBackButton() {
    if (handlePmocEditModal) return handlePmocEditModal();
    if (handleFunction) return handleFunction();
    if (selectedUnity.id) {
      if (!hasChangeUnity) {
        persistDataModal();
        return;
      }
      redirectCloseModal();
    } else {
      if (!hasCreateUnity) {
        persistDataModal();
        return;
      }
      redirectCloseModal();
    }
  }

  async function saveAddressInBudget() {
    const hasValidations = await ValidateInfos({ submiting: false });

    if (hasValidations) return;

    const unityInfo = mappedUnity({
      ...selectedUnity,
      name: unityName,
      responsible: responsibleUnity,
      postalCode: postalCodeUnity,
      street: streetUnity,
      district: districtUnity,
      number: numberUnity,
      city: cityUnity,
      uf: stateUnity,
      complement: complementUnity,
      additionalInfo: additionalInfoUnity,
      clientId: selectedUnity.clientId || client.id,
    });

    handleSetUnityClient(unityInfo);

    handleSetUnityClient(unity);

    addToast({
      type: "success",
      title: "Tudo certo!",
      description: `${modulePmoc ? "Unidade" : "Endereço"} salvo com sucesso!`,
    });

    setOpenModalAlert(false);
    handleToggleOpen && handleToggleOpen();
    handleFunction && handleFunction();
    handlePmocEditModal && handlePmocEditModal(unityInfo);
  }

  const moduleValidation = module !== "budget" && module !== "serviceOrder";

  async function checkingIfTheClientExists() {
    const hasClient = client && client.id && (await getClient(client.id));
    if (moduleValidation || !hasUnity) {
      ValidateInfos({ submiting: true });
      return;
    }

    if (!!hasClient) {
      const hasValidations = await ValidateInfos({ submiting: false });
      if (hasValidations) return;

      setOpenModalAlert(true);
    } else {
      saveAddressInBudget();
    }
  }

  async function handleTypeSave(type: string) {
    if (type === "first") {
      await saveAddressInBudget();
    } else if (type === "second") {
      await ValidateInfos({ submiting: true });
    }
  }

  const handleSubmitForm = (event: FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
  };

  return (
    <>
      {loadingPage ? (
        <LoadingProfiz isVisible={loadingPage} />
      ) : (
        <S.Container>
          <AlertModalCheckbox
            isVisible={openModalAlert}
            labelConfirm={`Somente  ${
              module === "serviceOrder"
                ? "nesta Ordem de serviço"
                : "neste Orçamento"
            }`}
            labelCancel={"Cadastro geral deste Cliente"}
            description={
              "Você quer que a alteração dos dados do Cliente seja aplicada:"
            }
            action="choose"
            handleConfirm={(type) => handleTypeSave(type)}
            onCloseModal={() => setOpenModalAlert(false)}
          />
          <AlertModal
            isVisible={isVisibleAlert}
            title={getAlertText(visibleAlertState).title}
            description={getAlertText(visibleAlertState).description}
            action="choose"
            handleConfirm={() => {
              visibleAlertState === 1 ? turnUnityToPrincipal() : deleteUnity();
            }}
            onCloseModal={() => setIsVisibleAlert(false)}
          />
          <S.Top>
            <S.BackButton onClick={handleBackButton}>
              <FiChevronLeft size={16} color={theme.colors.text} />
            </S.BackButton>

            <S.HeaderTitle>
              <p>
                {!!selectedUnity.name
                  ? `Editar ${modulePmoc ? "unidade" : "endereço"}`
                  : `Cadastrar ${modulePmoc ? "unidade" : "endereço"}`}
              </p>
              {selectedUnity.id && !selectedUnity.default && (
                <S.RegisteredUnities
                  onClick={() => {
                    setVisibleAlertState(1);
                    setIsVisibleAlert(true);
                  }}
                >
                  Definir como {modulePmoc ? "unidade" : "endereço"} principal
                </S.RegisteredUnities>
              )}
            </S.HeaderTitle>
          </S.Top>
          <S.Content
            id="form_cadastro_clientes_endereco"
            onSubmit={handleSubmitForm}
          >
            <S.WrapperInput>
              <Input
                name={`Nome ${modulePmoc ? "da unidade" : "do endereço"}*`}
                placeholder="Casa, apto, conjunto..."
                value={unityName}
                onChange={(e) => {
                  setUnityName(e.target.value.trimStart());
                }}
                hasError={hasError.unityName}
                onFocusCapture={() => {
                  setHasError({ ...hasError, unityName: "" });
                }}
              />
            </S.WrapperInput>

            {client.address && !!Object.keys(client.address).length && (
              <>
                {(!!selectedUnity.id && selectedUnity.default) || (
                  <S.WrapperInput>
                    <Input
                      name={`Responsável ${
                        modulePmoc ? "pela unidade" : "pelo endereço"
                      }*`}
                      placeholder={`Nome do responsável ${
                        modulePmoc ? "pela unidade" : "pelo endereço"
                      }`}
                      value={responsibleUnity}
                      onChange={(e) =>
                        setResponsibleUnity(e.target.value.trimStart())
                      }
                      disabled={checked[0]}
                      hasError={hasError.responsibleUnity}
                      onFocusCapture={() => {
                        setHasError({ ...hasError, responsibleUnity: "" });
                      }}
                    />

                    <S.CheckboxWrapper onClick={() => handleCheckboxCheck(0)}>
                      <S.CheckBox checked={checked[0]}>
                        <div />
                      </S.CheckBox>
                      <S.CheckboxText>
                        Igual ao {modulePmoc ? "da unidade" : "do endereço"}{" "}
                        principal
                      </S.CheckboxText>
                    </S.CheckboxWrapper>
                  </S.WrapperInput>
                )}
              </>
            )}

            {client.address && !!Object.keys(client.address).length && (
              <>
                {(!!selectedUnity.id && selectedUnity.default) || (
                  <S.WrapperInput>
                    <Input
                      name="E-mail"
                      placeholder="E-mail do cliente"
                      value={selectedUnity.email || ""}
                      disabled={checked[1]}
                      onChange={(e) => handleInputChange(e, "email")}
                    />

                    <S.CheckboxWrapper onClick={() => handleCheckboxCheck(1)}>
                      <S.CheckBox checked={checked[1]}>
                        <div />
                      </S.CheckBox>
                      <S.CheckboxText>
                        Igual ao {modulePmoc ? "da unidade" : "do endereço"}{" "}
                        principal
                      </S.CheckboxText>
                    </S.CheckboxWrapper>
                  </S.WrapperInput>
                )}
              </>
            )}
            {client.address && !!Object.keys(client.address).length && (
              <>
                {(!!selectedUnity.id && selectedUnity.default) || (
                  <S.WrapperInput>
                    <Input
                      name="Telefone do cliente"
                      placeholder="Telefone do cliente"
                      maskType="phone"
                      value={selectedUnity.phone || ""}
                      disabled={checked[2]}
                      onChange={(e) => handleInputChange(e, "phone")}
                    />

                    <S.CheckboxWrapper onClick={() => handleCheckboxCheck(2)}>
                      <S.CheckBox checked={checked[2]}>
                        <div />
                      </S.CheckBox>
                      <S.CheckboxText>
                        Igual ao {modulePmoc ? "da unidade" : "do endereço"}{" "}
                        principal
                      </S.CheckboxText>
                    </S.CheckboxWrapper>
                  </S.WrapperInput>
                )}
              </>
            )}

            <S.WrapperInput>
              <Input
                name="CEP*"
                placeholder="Insira o CEP"
                maskType="zip-code"
                value={postalCodeUnity}
                onChange={(e) => {
                  setPostalCodeUnity(e.target.value);
                }}
                hasError={hasError.postalCodeUnity}
                onFocusCapture={() => {
                  setHasError({ ...hasError, postalCodeUnity: "" });
                }}
                disabled={checked[3]}
              />

              {!selectedUnity.default && (
                <S.CheckboxWrapper onClick={() => handleCheckboxCheck(3)}>
                  <S.CheckBox checked={checked[3]}>
                    <div />
                  </S.CheckBox>
                  <S.CheckboxText>
                    Igual ao {modulePmoc ? "da unidade" : "do endereço"}{" "}
                    principal
                  </S.CheckboxText>
                </S.CheckboxWrapper>
              )}
            </S.WrapperInput>

            <S.GroupInputs>
              <div>
                <Input
                  name="Rua*"
                  placeholder="Rua"
                  value={streetUnity}
                  onChange={(e) => setStreetUnity(e.target.value.trimStart())}
                  hasError={hasError.streetUnity}
                  onFocusCapture={() => {
                    setHasError({ ...hasError, streetUnity: "" });
                  }}
                />
              </div>

              <div>
                <Input
                  name="Número*"
                  placeholder="Ex: 123"
                  value={numberUnity}
                  onChange={(e) => setNumberUnity(e.target.value.trimStart())}
                  hasError={hasError.numberUnity}
                  onFocusCapture={() => {
                    setHasError({ ...hasError, numberUnity: "" });
                  }}
                />
              </div>
            </S.GroupInputs>

            <S.WrapperInput>
              <Input
                name="Bairro*"
                placeholder="Bairro"
                value={districtUnity}
                onChange={(e) => setDistrictUnity(e.target.value.trimStart())}
                hasError={hasError.districtUnity}
                onFocusCapture={() => {
                  setHasError({ ...hasError, districtUnity: "" });
                }}
              />
            </S.WrapperInput>

            <S.GroupInputs>
              <div>
                <Input
                  name="Cidade*"
                  placeholder="Ex: São Paulo"
                  value={cityUnity}
                  onChange={(e) => setCityUnity(e.target.value.trimStart())}
                  hasError={hasError.cityUnity}
                  onFocusCapture={() => {
                    setHasError({ ...hasError, cityUnity: "" });
                  }}
                />
              </div>

              <div>
                <Dropdown
                  label="Estado*"
                  placeholder="Ex: SP"
                  options={states}
                  optionSelected={stateUnity}
                  onClickedValue={({ id, name }) => {
                    setStateUnity(name);
                  }}
                  hasError={hasError.stateUnity}
                />
              </div>
            </S.GroupInputs>

            <S.WrapperInput>
              <Input
                name="Complemento"
                placeholder={`Complemento ${
                  modulePmoc ? "da unidade" : "do endereço"
                }`}
                value={complementUnity}
                onChange={(e) => setComplementUnity(e.target.value.trimStart())}
              />
            </S.WrapperInput>

            <S.WrapperInput>
              <CommentsTextArea
                label="Informações adicionais"
                value={additionalInfoUnity}
                onChange={(e) =>
                  setAdditionalInfoUnity(e.target.value.trimStart())
                }
                placeholder={`Caso tenha observações sobre ${
                  modulePmoc ? "essa unidade" : "esse endereço"
                }, registre aqui`}
                hasError={hasErrorOnAdditionalInfoUnity}
              />
            </S.WrapperInput>

            <Button
              onClick={checkingIfTheClientExists}
              loading={loadingButton}
              disabled={loadingButton}
              type={"button"}
            >
              {selectedUnity.id
                ? `Atualizar ${modulePmoc ? "unidade" : "endereço"}`
                : `Adicionar ${
                    modulePmoc ? "unidade" : "endereço"
                  } ao cadastro`}
            </Button>

            {selectedUnity.id && !selectedUnity.default && (
              <>
                {moduleValidation && (
                  <ShowWhenHavePermission
                    moduleHash="client-unity"
                    actionHash="delete"
                  >
                    <Button
                      typeButton="delete"
                      type={"button"}
                      onClick={() => {
                        setVisibleAlertState(2);
                        setIsVisibleAlert(true);
                      }}
                    >
                      Excluir {modulePmoc ? "unidade" : "endereço"}
                    </Button>
                  </ShowWhenHavePermission>
                )}
              </>
            )}
          </S.Content>
        </S.Container>
      )}
    </>
  );
}
