import React, { useState } from "react";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { Link, useHistory } from "react-router-dom";
import { useTheme } from "styled-components";
import * as Yup from "yup";

import { GoogleButton } from "./SocialLogin/GoogleButton";

import { useToast } from "hooks/toast";
import { useAuth } from "hooks/auth";
import getValidationErrors from "utils/getValidationErrors";

import { Input } from "components/Input";
import { Button } from "components/Button";

import * as S from "./styles";
import profizLap from "assets/laptop5.svg";
import qrcode from "assets/qrcode.svg";
import profiz from "assets/Profiz.svg";
import seloLGPD from "assets/selo-lgpd.svg";

type Errors = {
  [key: string]: string;
};

export function SignIn() {
  const { addToast } = useToast();
  const theme = useTheme();
  const { signIn } = useAuth();
  const history = useHistory();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState<Errors>({} as Errors);

  async function handleSubmit(event: React.FormEvent) {
    try {
      event.preventDefault();
      setIsLoading(true);
      const schema = Yup.object().shape({
        email: Yup.string()
          .required("E-mail é obrigatório")
          .email("Digite um e-mail válido"),
        password: Yup.string().required("Senha é obrigatório"),
      });
      await schema.validate(
        { email, password },
        {
          abortEarly: false,
        }
      );
      setHasError({});

      await signIn({ email, password });
      history.push("/home");
    } catch (err: any) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);

        setHasError(errors);

        addToast({
          type: "error",
          title: "Erro ao logar",
          description: "Verifique suas credenciais",
        });

        return;
      }

      if (err.response.status >= 500 && err.response.status < 600) {
        addToast({
          type: "error",
          title: "Estamos com problemas técnicos",
          description: "Tente novamente daqui a pouquinho",
        });
        return;
      }

      addToast({
        type: "error",
        title: "Erro ao logar",
        description:
          err.response.status === 400
            ? err.response.data.error
            : "Verifique seu usuário e senha",
      });

      setHasError({
        password: "Senha ou e-mail inválido",
        email: "E-mail ou senha inválido",
      });
    } finally {
      setIsLoading(false);
    }
  }

  function handleRegister() {
    history.push("/register");
  }

  return (
    <S.BackgroundContent>
      <S.Background>
        <aside>
          <img className="profiz" src={profiz} alt="logo da profiz" />

          <section>
            <S.Title>
              Boas vindas <span>à Profiz!</span>
            </S.Title>

            <S.SubTitle>
              A solução <span> grátis </span>
              completa para <span> gestão de serviços.</span>
            </S.SubTitle>

            <img
              className="laptop"
              src={profizLap}
              alt="imagens do sistema profiz versao web e mobile"
            />

            <S.QrCode>
              <img src={qrcode} alt="qr code" />

              <div>
                <span>Baixe o aplicativo.</span>
                <p>Disponível para Android e iOS, escaneie o QR Code.</p>
              </div>
            </S.QrCode>
          </section>
        </aside>
      </S.Background>
      <S.Content>
        <S.AnimationContainer>
          <form onSubmit={handleSubmit}>
            <S.Title2>
              Entrar na <span>Profiz</span>
            </S.Title2>
            <S.Wrapper>
              <Input
                name="E-mail"
                type="email"
                placeholder="exemplo@ex.com"
                onChange={(text) => setEmail(text.currentTarget.value)}
                hasError={hasError.email}
                onFocusClearError={() =>
                  setHasError({ ...hasError, email: "" })
                }
              />
            </S.Wrapper>
            <S.Wrapper>
              <Input
                name="Senha"
                type={showPassword ? "text" : "password"}
                placeholder="Digite sua senha"
                onChange={(text) => setPassword(text.currentTarget.value)}
                hasError={hasError.password}
                onFocusClearError={() =>
                  setHasError({ ...hasError, password: "" })
                }
              />

              <S.ShowPassword
                type="button"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? (
                  <AiOutlineEyeInvisible
                    size={27}
                    color={theme.colors.subtitle}
                  />
                ) : (
                  <AiOutlineEye size={27} color={theme.colors.subtitle} />
                )}
              </S.ShowPassword>
            </S.Wrapper>
            <S.Wrapper>
              <Link to="/forgot-password">Esqueci minha senha </Link>
            </S.Wrapper>
            <Button type="submit" loading={isLoading}>
              Entrar
            </Button>
            <S.Dividers>
              <S.Divider /> <span>ou</span>
              <S.Divider />
            </S.Dividers>

            <S.Wrapper>
              <GoogleButton text="Continuar com o Google" />
            </S.Wrapper>

            <S.Divider />
            <S.SocialAreaContent>
              <p>
                Ainda não possui uma conta ?{" "}
                <span onClick={handleRegister}> Cadastre-se </span>
              </p>

              <a
                href="https://www.privacidade.com.br/portal-de-privacidade?token=ce7837048e0df0a69e23b01c86c2aa8b"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={seloLGPD} alt="Selo LGPD" />
              </a>
            </S.SocialAreaContent>
          </form>
        </S.AnimationContainer>
      </S.Content>
    </S.BackgroundContent>
  );
}
