import { Switch, Route } from "react-router-dom";
import { PrivacyPolicies } from "pages/common/PrivacyPolicies";

export function PublicRoutes() {
  return (
    <Switch>
      <Route
        path="/public/privacy-policies"
        exact
        component={PrivacyPolicies}
      />
    </Switch>
  );
}
