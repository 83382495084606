import { FormEvent, useEffect, useState } from "react";
import * as Yup from "yup";

import { Button } from "components/Button";
import { ArrowButton } from "components/ArrowButton";
import { ContentLeft } from "templates/ContentLeft";

import { ClientArea } from "./ClientArea";
import { ServiceArea } from "./ServiceArea";
import { ServiceOrderOrReceiptArea } from "./ServiceOrderOrReceiptArea";
import { DefaultObservationsArea } from "./DefaultObservationsArea";

import { useClient } from "hooks/budget/client";
import { useSelectedService } from "hooks/budget/selectedServices";
import { useSelectedServiceOrder } from "hooks/selectedServiceOrder";
import { useObservation } from "hooks/budget/observation";
import { useToast } from "hooks/toast";

import * as S from "./styles";
import getValidationErrors from "utils/getValidationErrors";
import { containsEscapeCharacters } from "utils/containsEscapeCharacters ";
import { mappedServicesForApi } from "utils/mappedServicesForApi";
import apiv2 from "services/apiv2";
import { useHistory } from "react-router";
import { UnityAreaComponent } from "components/UnityAreaComponent";
import axios from "axios";

type Errors = {
  [key: string]: string;
};

export function NewServiceOrder() {
  const { addToast } = useToast();
  const { goBack } = useHistory();

  const { client, clearClientData, clearUnityData, unity } = useClient();
  const { selectedServices, clearServicesData } = useSelectedService();
  const { selectedServiceOrder, handleClearSelectedServiceOrder } =
    useSelectedServiceOrder();
  const { text, handleSetText, clearObservationData } = useObservation();

  const [nfInputValue, setNfInputValue] = useState("");
  const [typeDocument, setTypeDocument] = useState<"NF" | "OS">("NF");

  const [loading, setLoading] = useState(false);

  const [hasError, setHasError] = useState<Errors>({} as Errors);
  const [hasErrorOnClient, setHasErrorOnClient] = useState("");
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [hasErrorOnUnity, setHasErrorOnUnity] = useState("");
  const [hasErrorOnServices, setHasErrorOnServices] = useState("");
  const [hasErrorOnText, setHasErrorOnText] = useState("");

  useEffect(() => {
    return clearAllData();
  }, []); // eslint-disable-line

  function clearAllData() {
    clearClientData();
    clearServicesData();
    handleClearSelectedServiceOrder();
    clearObservationData();
    setNfInputValue("");
    clearUnityData();
  }

  function handleGetLinkedDocument(type: "NF" | "OS") {
    setTypeDocument(type);
  }

  useEffect(() => {
    //Ao trocar de cliente, retira o erro.
    if (hasErrorOnClient) {
      setHasErrorOnClient("");
    }
  }, [client]); // eslint-disable-line react-hooks/exhaustive-deps

  async function handleCreateNewServiceOrder() {
    try {
      setLoading(true);

      if (!client.id) {
        setHasErrorOnClient("Cliente é obrigatório");
      } else {
        setHasErrorOnClient("");
      }

      if (!selectedServices.length) {
        setHasErrorOnServices("Serviço é obrigatório");
      } else {
        setHasErrorOnServices("");
      }

      if (containsEscapeCharacters(text)) {
        setHasErrorOnText('Não é permitido uso de barra invertida "\\"');
        addToast({
          title: "Ops!!",
          description: 'Não é permitido uso de barra invertida "\\"',
          type: "error",
        });

        return;
      } else {
        setHasErrorOnText("");
      }

      const schema = Yup.object().shape({
        nfInputValue: Yup.string(),
      });

      await schema.validate({ nfInputValue }, { abortEarly: false });

      setHasError({} as Errors);

      if (!client.id || !selectedServices.length) {
        return addToast({
          title: "Ops!!",
          description: "Alguns campos são obrigatórios",
          type: "error",
        });
      }
      let clientAddress =
        Object.keys(unity).length > 0
          ? unity
          : {
              id: 0,
              clientId: "",
              name: "",
              responsible: "",
              email: "",
              phone: "",
              postalCode: "",
              street: "",
              district: "",
              number: "",
              complement: "",
              city: "",
              uf: "",
              additionalInfo: "",
              default: true,
              createdAt: "",
              environments: [],
            };

      const newServiceOrderData = {
        client: {
          ...client,
          address: clientAddress,
        },
        services: mappedServicesForApi(selectedServices),
        reference: {
          id:
            typeDocument === "OS"
              ? selectedServiceOrder.id
                ? selectedServiceOrder.id
                : ""
              : nfInputValue,
          type: typeDocument === "OS" ? "service-order" : "other",
        },
        observations: text.trim(),
        originCreate: "web",
      };

      await apiv2.post("budgets/service-order", newServiceOrderData);

      addToast({
        title: "Tudo certo!",
        description: "Ordem de serviço criada com sucesso",
        type: "success",
      });

      goBack();
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);
        setHasError(errors);
        return;
      }
      addToast({
        title: "Ops!!",
        description:
          axios.isAxiosError(error) && error.response?.data.error
            ? error.response.data.error
            : "Ocorreu um erro ao criar a ordem de serviço.",
        type: "error",
      });
    } finally {
      setLoading(false);
    }
  }

  const handleSubmitForm = (event: FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
  };

  return (
    <S.Container>
      <ContentLeft>
        <header>
          <div>
            <ArrowButton />
          </div>

          <h1>Nova ordem de serviço</h1>
        </header>

        <S.Main id="form_ordem_servico" onSubmit={handleSubmitForm}>
          <ClientArea module={"serviceOrder"} hasError={hasErrorOnClient} />

          <S.Wrapper>
            <UnityAreaComponent
              module={"serviceOrder"}
              hasError={hasErrorOnUnity}
              label={"Adicionar endereço a ordem de serviço"}
            />
          </S.Wrapper>

          <ServiceArea hasError={hasErrorOnServices} />

          <S.Wrapper>
            <ServiceOrderOrReceiptArea
              nfInputValue={nfInputValue}
              onChangeInput={setNfInputValue}
              hasErrorInput={hasError.nfInputValue}
              onGetLinkedDocument={handleGetLinkedDocument}
            />
          </S.Wrapper>

          <S.Wrapper>
            <DefaultObservationsArea
              valueTextArea={text}
              onChangeTextArea={handleSetText}
              hasError={hasErrorOnText}
            />
          </S.Wrapper>

          <S.Wrapper>
            <Button loading={loading} onClick={handleCreateNewServiceOrder}>
              Gerar ordem de serviço
            </Button>
          </S.Wrapper>
        </S.Main>
      </ContentLeft>
    </S.Container>
  );
}
