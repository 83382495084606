import styled, { css } from "styled-components";

export const BoxAlert = styled.div`
  ${({ theme }) => css`
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    z-index: ${theme.layers.alwaysOnTop};
    position: absolute;
    width: 800px;
    height: 77px;
    border-radius: 8px;
    padding: 16px;
    background-color: ${theme.colors.text_extra_light};
    border: 1px solid #d6d6d6;
    display: flex;
    flex-direction: row;
    margin-bottom: 12px;

    p {
      font-family: ${theme.font.family};
      font-size: 12px;
      font-weight: 400;
      line-height: 15px;
      color: #000000;
    }

    a {
      font-family: ${theme.font.family};
      font-size: 12px;
      font-weight: 500;
      line-height: 15px;
      color: #000000;
    }
  `}
`;

export const WrapperButtons = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const ButtonPreferences = styled.button`
  ${({ theme }) => css`
    font-family: ${theme.font.family};
    font-size: 12px;
    font-weight: 500;
    color: #000000;
    line-height: 15px;
    text-decoration: underline;
  `}
`;

export const ButtonAcceptAllCookies = styled.button`
  ${({ theme }) => css`
    width: 120px;
    height: 45px;
    border-radius: 8px;
    padding: 10px;
    background-color: #3a5100;
    color: ${theme.colors.text_extra_light};
    font-family: ${theme.font.family};
    font-weight: 700;
    font-size: 12px;
    line-height: 15px;
  `}
`;

export const BoxPreferencesCookies = styled.div`
  ${({ theme }) => css`
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: ${theme.layers.alwaysOnTop};
    position: absolute;
    width: 800px;
    height: 500px;
    border-radius: 8px;
    background-color: ${theme.colors.text_extra_light};
    border: 1px solid #d6d6d6;
    padding: 10px 10px 16px 10px;
    gap: 10px;
  `}
`;

export const BoxHeader = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;

    div {
      display: flex;
      width: 100%;
      justify-content: end;
    }

    h1 {
      font-family: ${theme.font.family};
      font-size: 20px;
      font-weight: 700;
      line-height: 24px;
      color: ${theme.colors.menu_background};
    }

    a {
      font-family: ${theme.font.family};
      font-size: 14px;
      font-weight: 400;
      line-height: 17px;
      color: ${theme.colors.menu_background};
      text-decoration: underline;
    }
  `}
`;

export const BoxContent = styled.div`
  ${({ theme }) => css`
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    width: 780px;
    height: 300px;
    border-radius: 8px;
    border: 1px solid #d6d6d6;
    padding: 10px 4px 10px 10px;
    gap: 10px;
    overflow-y: auto;

    div {
      display: flex;
      flex-direction: column;
      width: 754px;
      height: 135px;
      border-radius: 8px;
      padding: 8px 10px 8px 10px;
      gap: 4px;
      background-color: #ededed;
    }

    h3 {
      font-family: ${theme.font.family};
      font-size: 14px;
      font-weight: 700;
      line-height: 17px;
      color: ${theme.colors.menu_background};
    }

    p {
      font-family: ${theme.font.family};
      font-size: 14px;
      font-weight: 400;
      line-height: 17px;
      color: ${theme.colors.menu_detail_light};
      text-align: justify;
    }
  `}
`;

export const ToggleContainer = styled.label`
  margin-left: 10px;
  position: relative;
  display: inline-block;
  width: 50px;
  height: 25px;
`;

export const HiddenCheckbox = styled.input.attrs({ type: "checkbox" })`
  opacity: 0;
  width: 0;
  height: 0;
  background: red;
`;

export const Slider = styled.span<{ checked: boolean }>`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${(props) => (props.checked ? "#305100" : "#cccccc")};
  transition: 0.4s;
  border-radius: 25px;

  &::before {
    position: absolute;
    content: "";
    height: 18px;
    width: 18px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: 0.4s;
    border-radius: 50%;
    transform: ${(props) =>
      props.checked ? "translateX(25px)" : "translateX(0)"};
  }
`;

export const BoxFooter = styled.div`
  ${({ theme }) => css`
    margin-top: 10px;

    span {
      font-family: ${theme.font.family};
      font-size: 12px;
      font-weight: 400;
      line-height: 15px;
      color: ${theme.colors.menu_background};
    }
  `}
`;
