import { useHistory } from "react-router";

import { useAuth } from "hooks/auth";
import { ArrowButton } from "components/ArrowButton";
import * as S from "./styles";

const PrivacyPolicies = () => {
  const history = useHistory();
  const { user } = useAuth();

  return (
    <S.Container>
      {user?.id && <ArrowButton handleFunction={() => history.push("/home")} />}

      <S.Description>
        <S.Title>Políticas de privacidade</S.Title>
        <br />
        <h3>1. Objetivo</h3>
        <br /> <br />
        <p>
          Esse documento de Política de Privacidade tem como objetivo apresentar
          aos nossos usuários, de maneira simples e acessível, a maneira como a
          Profiz cuida da relação à privacidade e proteção de dados pessoais de
          nossos usuários.
          <br /> <br />A Profiz tem o compromisso de cuidar de seus dados com
          segurança e honestidade, seguindo a Lei Geral de Proteção de Dados
          (LGPD).
        </p>
        <br /> <br />
        <h3>2. Quais dados utilizamos e como coletamos?</h3>
        <br /> <br />
        <p>
          Para que a gente consiga prestar alguns serviços, é necessário que
          tenhamos seus dados pessoais, como o seu nome, endereço, telefone,
          profissão, idade, geolocalização, número de IP e outros. <br /> <br />{" "}
          Lembrando que você sempre pode se recusar a fornecer seus dados
          pessoais, porém, sem eles não conseguimos te oferecer nossos serviços.
        </p>
        <br /> <br />
        <strong>Tipo de dados que coletamos</strong>
        <br />
        <p>
          <strong>Dados pessoais fornecidos diretamente por você: </strong>
          informações inseridas ou encaminhadas ao acesso ao app Profiz,
          shopping virtual da Profiz ou em solicitações feitas em nosso
          atendimento.
        </p>
        <br />
        <p>
          <strong>Dados pessoais fornecidos por terceiros: </strong>a Profiz
          pode receber seus dados através de terceiros, por via de nossos
          parceiros, prestadores de serviços, ou por aqueles que tenham algum
          relacionamento com você.
        </p>
        <br />
        <p>
          <strong>Dados pessoais coletados automaticamente: </strong>
          também coletamos alguns dados de forma automática, que são chamados de
          informações navegacionais, como cookies e seu endereço de IP.
        </p>
        <br /> <br />
        <h3>3. Por que coletamos?</h3>
        <br /> <br />
        <p>Para contratação e execução de serviços;</p>
        <p>
          Avaliação de desempenho de nossos serviços, pesquisa e ofertas de
          produtos;
        </p>
        <p>Segurança e prevenção de fraudes;</p>
        <p>Para o cumprimento de obrigação legal ou regulatória;</p>
        <p>
          Para o exercício regular de direitos em processo judicial,
          administrativo ou arbitral;
        </p>
        <p>
          Comunicação com os clientes e usuários, informar novos eventos e envio
          de mensagens publicitárias.
        </p>
        <br /> <br />
        <h3>4. Cancelamento e exclusão de dados</h3>
        <br /> <br />
        <p>
          Você pode sempre optar por não receber mais qualquer e-mail da Profiz,
          também podendo buscar pelo campo “Descadastrar-se” para que a
          atividade seja encerrada da organização. <br /> <br />
          Os dados coletados serão tratados sem uma data determinada, e para
          alterar suas informações pessoais ou excluí-las do nosso banco de
          dados, deverá pedir uma solicitação através do e-mail:
          juridico@profiz.com.br.
        </p>
        <br /> <br />
        <h3>5. As informações serão compartilhadas?</h3>
        <br /> <br />
        <p>
          A Profiz apenas compartilha seus dados quando é necessário ou
          pertinente para a execução de nossos serviços, como nas seguintes
          hipóteses abaixo.
        </p>
        <br />
        <strong>Prestadores de serviço</strong>
        <br />
        <p>
          Suas informações compartilhadas com os prestadores de serviços são
          apenas as necessárias, e eles são obrigados a não compartilhá-las,
          comercializar, armazenar ou utilizar para qualquer outro fim.
        </p>
        <br /> <br />
        <strong>Obrigação legal ou regulatória</strong>
        <br />
        <p>
          A Profiz pode ser solicitada a compartilhar seus dados pessoais em
          atendimento à ordem judicial, solicitações de autoridades
          administrativas, ao cumprimento de obrigação legal ou regulatória,
          assim como atuar de forma colaborativa em investigações de prevenção e
          combate a atos ilícitos.
        </p>
        <br />
        <strong>Mediante a sua solicitação</strong>
        <br />
        <p>
          A Profiz pode compartilhar os seus dados caso você solicite para links
          e sites terceiros. Nesse caso, os seus dados serão tratados de acordo
          com a Política de Privacidade desses terceiros e a Profiz não pode
          controlar ou se responsabilizar por essas práticas. <br /> <br />
          Por isso aconselhamos a ler o regulamento da Política de Privacidade
          antes.
        </p>
        <br /> <br />
        <h3>6. Direitos do Usuário</h3>
        <br /> <br />
        <p>
          Como titular de seus dados, você pode exercer diversos direitos, como
          consta no artigo 18 da Lei Geral de Proteção de Dados (LGPD), tais
          como:
          <br /> <br />
          acesso, confirmação da existência de tratamento de informações sobre
          compartilhamento;
          <br />
          corrigir ou solicitar a correção de Dados Pessoais incompletos,
          inexatos ou desatualizados;
          <br />
          solicitar o anonimato, bloqueio ou eliminação de dados pessoais
          desnecessários e excessivos ou tratados em desconformidade com a LGPD;
          <br />
          solicitar a portabilidade dos dados pessoais a outro fornecedor ou de
          serviços similares;
          <br />
          peticionar em relação aos seus dados perante à Autoridade Nacional de
          Proteção de Dados;
          <br />
          solicitar a eliminação dos dados pessoais coletados ou utilizados com
          base no seu consentimento;
          <br />
          obter informações sobre as entidades públicas ou privadas com as quais
          compartilhamos os seus dados pessoais;
          <br />
          quando a atividade de tratamento necessitar do seu consentimento, você
          pode recusá-la, assim como saber quais as consequências de você não
          fornecer o seu consentimento nesse caso;
          <br />
          quando a atividade de tratamento necessitar do seu consentimento, a
          qualquer momento você pode revogá-la.
        </p>
        <br /> <br />
        <h3>7. Segurança das informações</h3>
        <br /> <br />
        <p>
          A Profiz usa de vários artifícios para manter a privacidade de dados
          pessoais de seus usuários armazenados em sua base, procurando manter
          um ambiente seguro, com o uso das ferramentas abaixo.
        </p>
        <br />
        <strong>Criptografia</strong>
        <br />
        <p>
          Esse é um processo de codificação de dados e informações, ou seja, os
          dados são “embaralhados” na origem e decodificados para dificultar uma
          decifração pela internet.
        </p>
        <br />
        <strong>Firewall</strong>
        <br />
        <p>
          É o nome dado a um dispositivo físico e/ou lógico de uma rede de
          computadores, com o objetivo de aplicar uma política de segurança a um
          determinado ponto de controle da rede. Basicamente a sua função é
          administrar o tráfego de dados entre redes diferentes e impedir o
          compartilhamento e/ou recepção de acessos nocivos ou não autorizados
          de uma rede para a outra.
        </p>
        <br />
        <strong>Sistema de Transações na Internet</strong>
        <br />
        <p>
          É um sistema com validação da Verisign, esse programa permite que a
          cada acesso feito na rede da Profiz, uma nova chave de criptografia é
          criada entre o computador do usuário e o de nosso portal,
          impossibilitando que outras pessoas interceptem a transação através de
          programas.
        </p>
        <br />
        <strong>Tecnologia SSL EV</strong>
        <br />
        <p>
          Essa tecnologia é em especial usada no momento final de suas compras,
          para que as suas informações passem por um processo de encriptação com
          tecnologia SSL EV (Secure Socket Loker Extend Validation) para que
          nenhuma outra pessoa tenha acesso a elas além da Profiz.
        </p>
        <br /> <br />
        <h3>8. Utilizamos cookies</h3>
        <br /> <br />
        <p>
          Cookies são uma espécie de arquivo residuais que permitem os usuários
          a visitar praticamente todos os sites, eles servem para personalizar a
          experiência do usuário e identificar a pessoa que está acessando
          aquele conteúdo. Os cookies não armazenam nenhuma informação pessoal,
          apenas contêm os dados de login da pessoa.
        </p>
        <br />
        <strong>Por que é necessário?</strong>
        <br />
        <p>
          É essencial para que nossos visitantes naveguem em nosso site, não é
          possível desabilitar e nem se opor ao tratamento.
        </p>
        <br />
        <strong>Desempenho dos Cookies</strong>
        <br />
        <p>
          São utilizados para compreender como os visitantes interagem com
          nossos sites, coletando informações com o tempo de visita.
        </p>
        <br />
        <strong>Funcionalidades</strong>
        <br />
        <p>
          Os Cookies permitem que os usuários tenham uma experiência
          personalizada aos usuários, pois lembram das escolhas feitas pelo
          mesmo.
        </p>
        <br />
        <strong>Marketing</strong>
        <br />
        <p>Utilizado para promover conteúdos de interesse do usuário.</p>
        <br /> <br />
        <h3>9. Contato</h3>
        <br /> <br />
        <p>
          Caso tenha ficado qualquer dúvida sobre essa política, sobre o
          tratamento dos seus dados pessoais ou ainda, caso queira exercer seus
          direitos, você pode nos contactar através do e-mail:
          juridico@profiz.com.br.
        </p>
      </S.Description>
    </S.Container>
  );
};

export { PrivacyPolicies };
