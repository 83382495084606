import { FormEvent, useEffect, useState } from "react";
import { useTheme } from "styled-components";
import { FiChevronLeft } from "react-icons/fi";
import * as Yup from "yup";
import { isEqual } from "lodash";

import { Input } from "components/Input";
import { Button } from "components/Button";
import { CommentsTextArea } from "components/CommentsTextArea";

import { useClient } from "hooks/budget/client";
import { useToast } from "hooks/toast";
import getValidationErrors from "utils/getValidationErrors";
import { containsEscapeCharacters } from "utils/containsEscapeCharacters ";

import { getCep } from "services/cep";

import * as S from "./styles";
import { useConfirmModalClient } from "hooks/budget/confirmModalClient";

type Errors = {
  [key: string]: string;
};

type CreateClientProps = {
  handleToggleOpen: () => void;
};

type TViaCepProps = {
  cep: string;
  logradouro: string;
  complemento?: string;
  bairro: string;
  localidade: string;
  uf: string;
  ibge: string;
  gia?: string;
  ddd?: string;
  siafi?: string;
  erro?: string;
};

export function FormNewAddress({ handleToggleOpen }: CreateClientProps) {
  const theme = useTheme();

  const { address, handleSetAddress, clearClientData, hasAddress } =
    useClient();

  const { addToast } = useToast();
  const { handleChangeModalProps, setConfirmModalProps, setHasChangeds } =
    useConfirmModalClient();

  const [unityName, setUnityName] = useState("");
  const [cep, setCep] = useState("");
  const [city, setCity] = useState("");
  const [street, setStreet] = useState("");
  const [district, setDistrict] = useState("");
  const [number, setNumber] = useState("");
  const [uf, setUf] = useState("");
  const [complement, setComplement] = useState("");
  const [additionalInfo, setAdditionalInfo] = useState("");

  const [loadingButton, setLoadingButton] = useState(false);

  const [hasError, setHasError] = useState<Errors>({} as Errors);
  const [hasErrorOnAdditionalInfo, setHasErrorOnAdditionalInfo] = useState("");

  useEffect(() => {
    if (address?.name) {
      setInfoUnity();
    } else {
      setUnityName("");
      setCep("");
      setStreet("");
      setDistrict("");
      setNumber("");
      setCity("");
      setUf("");
      setComplement("");
      setAdditionalInfo("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function setInfoUnity() {
    try {
      address?.name && setUnityName(address.name);
      address?.postalCode && setCep(address.postalCode);
      address?.street && setStreet(address.street);
      address?.district && setDistrict(address.district);
      address?.number && setNumber(address.number);
      address?.city && setCity(address.city);
      address?.uf && setUf(address.uf);

      address?.complement && setComplement(address.complement);
      address?.additionalInfo && setAdditionalInfo(address.additionalInfo);
    } catch {
      addToast({
        title: "Ops!",
        description: "Ocorreu um erro ao carregar os dados.",
        type: "error",
      });
    }
  }

  async function handleLoadingCep() {
    const formattedCep = cep.replaceAll("_", "").replace("-", "");
    if (formattedCep.length < 8) return;

    try {
      const response = (await getCep(cep)) as TViaCepProps;

      if (response.erro) {
        addToast({
          type: "error",
          title: "CEP não encontrado",
          description: "Verifique o CEP informado",
        });
      }

      setStreet(response.logradouro);
      setDistrict(response.bairro);
      setCity(response.localidade);
      setUf(response.uf);
    } catch {
      addToast({
        title: "Ops!",
        description: "Ocorreu um erro ao carregar os dados de CEP.",
        type: "error",
      });
    }
  }

  const addressData = {
    name: unityName,
    postalCode: cep,
    street,
    number,
    district,
    city,
    uf,
    complement,
    additionalInfo,
    default: true,
  };

  async function handleSubmitDataClient() {
    setLoadingButton(true);
    try {
      const schema = () => {
        return Yup.object().shape({
          unityName: Yup.string().required("Nome do endereço é obrigatório"),
          cep: Yup.string().required("CEP é obrigatório."),
          street: Yup.string().required("Rua é obrigatório."),
          number: Yup.string().required("Obrigatório."),
          district: Yup.string().required("Bairro é obrigatório."),
          city: Yup.string().required("Cidade é obrigatório."),
          uf: Yup.string().required("Obrigatório."),
        });
      };

      await schema().validate(
        {
          unityName,
          cep,
          street,
          number,
          district,
          city,
          uf,
        },
        { abortEarly: false }
      );

      if (containsEscapeCharacters(additionalInfo)) {
        setHasErrorOnAdditionalInfo(
          'Não é permitido uso de barra invertida "\\"'
        );
        addToast({
          title: "Ops!!",
          description: 'Não é permitido uso de barra invertida "\\"',
          type: "error",
        });

        return;
      } else {
        setHasErrorOnAdditionalInfo("");
      }

      setHasError({});

      handleSetAddress(addressData);

      addToast({
        title: "Certo!",
        description: "Endereço cadastrado com sucesso",
        type: "success",
      });

      handleToggleOpen();
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);
        setHasError(errors);

        addToast({
          title: "Ops...",
          description: "Preencha todos os campos obrigatórios",
          type: "error",
        });

        return;
      }

      addToast({
        title: "Ops...",
        description: "Ocorreu um erro ao cadastrar o cliente",
        type: "error",
      });
    } finally {
      setLoadingButton(false);
    }
  }

  function handleConfirm(): void {
    setConfirmModalProps(false);
  }

  function handleCloseModal(): void {
    setConfirmModalProps(false);
    handleToggleOpen();
  }

  const addressDataDefault = {
    additionalInfo: "",
    city: "",
    complement: "",
    default: true,
    district: "",
    name: "",
    number: "",
    postalCode: "",
    street: "",
    uf: "",
  };

  const hasCreateAddress = isEqual(
    addressData,
    hasAddress ? address : addressDataDefault
  );

  useEffect(() => {
    setHasChangeds(hasCreateAddress);
  }, [setHasChangeds, hasCreateAddress]);

  function handleBackButton() {
    if (!hasCreateAddress) {
      handleChangeModalProps({
        value: true,
        handleConfirm,
        handleCloseModal: () => {
          handleCloseModal();
        },
      });
      return;
    }
    handleToggleOpen();
  }

  const handleSubmitForm = (event: FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
  };

  return (
    <>
      <S.Top>
        <S.BackButton onClick={handleBackButton}>
          <FiChevronLeft size={16} color={theme.colors.text} />
        </S.BackButton>

        <p>
          {" "}
          {address?.name
            ? "Editar endereço principal"
            : "Cadastrar endereço principal"}{" "}
        </p>
      </S.Top>

      <S.Content
        id="form_cadastro_clientes_endereco"
        onSubmit={handleSubmitForm}
      >
        <S.WrapperInput>
          <Input
            name="Nome do endereço*"
            placeholder="Casa, apto, conjunto..."
            value={unityName}
            onChange={(e) => setUnityName(e.target.value.trimStart())}
            hasError={hasError.unityName}
            onFocusClearError={() =>
              setHasError({ ...hasError, unityName: "" })
            }
          />
        </S.WrapperInput>

        <S.WrapperInput>
          <Input
            name="CEP*"
            maskType="zip-code"
            placeholder="99999-999"
            value={cep}
            onChange={(e) => setCep(e.target.value)}
            handleEditingBlur={() => handleLoadingCep()}
            hasError={hasError.cep}
            onFocusClearError={() => setHasError({ ...hasError, cep: "" })}
          />
        </S.WrapperInput>

        <S.GroupInputs>
          <div>
            <Input
              name="Rua*"
              placeholder="Nome da rua"
              value={street}
              onChange={(e) => setStreet(e.target.value.trimStart())}
              hasError={hasError.street}
              onFocusClearError={() => setHasError({ ...hasError, street: "" })}
            />
          </div>

          <div>
            <Input
              name="Número*"
              placeholder="Ex: 123"
              value={number}
              onChange={(e) => setNumber(e.target.value.trimStart())}
              hasError={hasError.number}
              onFocusClearError={() => setHasError({ ...hasError, number: "" })}
            />
          </div>
        </S.GroupInputs>

        <S.WrapperInput>
          <Input
            name="Bairro*"
            placeholder="Nome do bairro"
            value={district}
            onChange={(e) => setDistrict(e.target.value.trimStart())}
            hasError={hasError.district}
            onFocusClearError={() => setHasError({ ...hasError, district: "" })}
          />
        </S.WrapperInput>

        <S.GroupInputs>
          <div>
            <Input
              name="Cidade*"
              placeholder="Ex: São Paulo"
              value={city}
              onChange={(e) => setCity(e.target.value.trimStart())}
              hasError={hasError.city}
              onFocusClearError={() => setHasError({ ...hasError, city: "" })}
            />
          </div>

          <div>
            <Input
              name="UF*"
              maxLength={2}
              placeholder="Ex: SP"
              value={uf}
              onChange={(e) => setUf(e.target.value.trimStart())}
              hasError={hasError.uf}
              onFocusClearError={() => setHasError({ ...hasError, uf: "" })}
            />
          </div>
        </S.GroupInputs>

        <S.WrapperInput>
          <Input
            name="Complemento"
            placeholder="Complemento do endereço"
            value={complement}
            onChange={(e) => setComplement(e.target.value.trimStart())}
          />
        </S.WrapperInput>

        <S.WrapperInput>
          <CommentsTextArea
            label="Informações adicionais"
            placeholder="Caso tenha observações sobre esse endereço, registre aqui."
            value={additionalInfo}
            onChange={(e) => setAdditionalInfo(e.target.value.trimStart())}
            hasError={hasErrorOnAdditionalInfo}
          />
        </S.WrapperInput>
        <S.WrapperButton>
          <Button
            loading={loadingButton}
            onClick={() => {
              handleSubmitDataClient();
              clearClientData();
            }}
          >
            Salvar endereço
          </Button>
        </S.WrapperButton>
      </S.Content>
    </>
  );
}
