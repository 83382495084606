import React, { useEffect, useState } from "react";

import { Dropdown, DropdownOption } from "components/DropDown";
import { Input } from "components/Input";
import { CommentsTextArea } from "components/CommentsTextArea";
import { Button } from "components/Button";
import { useServiceDropDown } from "hooks/servicesDropDown";
import { useServiceFormTree } from "hooks/serviceFormTree";

import { api } from "services/api";
import arrowLeft from "assets/icons/arrow-left.svg";
import { containsEscapeCharacters } from "utils/containsEscapeCharacters ";

import * as S from "./styles";
import axios from "axios";
import { useToast } from "hooks/toast";
import { LoadingProfiz } from "components/LoadingProfiz";
import { PhotoCardStep } from "pages/fieldService/ServicesHub/PhotoCardStep";
import { ServiceRegisterPhoto } from "components/ServiceRegisterPhoto";
import { AddPhoto } from "components/AddPhoto";
import { FileImageProps, Photo } from "../types";
import { useAuth } from "hooks/auth";
import { CarouselImages } from "components/ModalGalleryRegisterImages/CarouselImages";

const warrantyExtensionOptions = [
  { id: 1, name: "Dias" },
  { id: 2, name: "Meses" },
  { id: 3, name: "Anos" },
];

type FormCreateProductsProps = {
  handleGoBack: () => void;
  refetchListProducts?: () => void;
};

type ExtensionProps = {
  id: number;
  name: string;
};

export function FormCreateProduct({
  handleGoBack,
  refetchListProducts,
}: FormCreateProductsProps) {
  const { addToast } = useToast();
  const { categoriesTree, handleSetCategoriesTree } = useServiceFormTree();
  const { selectedCategoryId, handleCategoryId, reset } = useServiceDropDown();

  const [isLoadingButton, setIsLoadingButton] = useState(false);
  const [loading, setLoading] = useState(false);

  const [hasErrorOnCategory, setHasErrorOnCategory] = useState("");

  const [warrantyTime, setWarrantyTime] = useState("");
  const [hasErrorOnWarrantyTime, setHasErrorOnWarrantyTime] = useState("");

  const [warrantyExtension, setWarrantyExtension] = useState(
    {} as ExtensionProps
  );
  const [hasErrorOnWarrantyExtension, setHasErrorOnWarrantyExtension] =
    useState("");

  const [brand, setBrand] = useState("");
  const [observationText, setObservationText] = useState("");

  const [price, setPrice] = useState("");
  const [unMaskedPrice, setUnmaskedPrice] = useState(0);
  const [hasErrorOnPrice, setHasErrorOnPrice] = useState("");
  const [productInput, setProductInput] = useState("");
  const [hasErrorOnProductInput, setHasErrorOnProductInput] = useState("");

  const { user } = useAuth();
  const [loadingPhotoId, setLoadingPhotoId] = useState(0);
  const [photosInState, setPhotosInState] = useState<Photo[]>([]);
  const [photosToDeleteBeforeSave, setPhotosToDeleteBeforeSave] = useState<
    number[]
  >([]);
  const [loadingPhoto, setLoadingPhoto] = useState(false);
  const [stagePhotoData, setStagePhotoData] = useState<Photo[]>([]);
  const [selectedPhotoIndex, setSelectedPhotoIndex] = useState(0);
  const [photoModal, setPhotoModal] = useState(false);
  const [hasErrorOnObservationText, setHasErrorOnObservationText] =
    useState("");

  async function getCategories() {
    try {
      setLoading(true);
      const response = await api.get("/services/new-service-form");
      const { categoriesTree: categoriesTreeResponse } = response.data;

      handleSetCategoriesTree(categoriesTreeResponse);
    } catch (err) {
      addToast({
        title: "Ops!!",
        description:
          axios.isAxiosError(err) && err.response?.data.error
            ? err.response.data.error
            : "Não foi possível carregar os dados de categoria.",
        type: "error",
      });
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getCategories();
    return () => {
      reset();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  function handleSelectCategory({ id }: DropdownOption) {
    if (selectedCategoryId === id) {
      return;
    }
    handleCategoryId(id);
    setHasErrorOnCategory("");
  }

  function handleWarrantyExtension({ id }: DropdownOption) {
    const findCurrentExtension = warrantyExtensionOptions.find(
      (extension) => extension.id === id
    );

    if (findCurrentExtension) {
      setWarrantyExtension(findCurrentExtension);
    }

    setHasErrorOnWarrantyExtension("");
  }

  async function handleUpdateProduct() {
    if (!selectedCategoryId) {
      setHasErrorOnCategory("Categoria é obrigatório");
    } else {
      setHasErrorOnCategory("");
    }

    if (!productInput) {
      setHasErrorOnProductInput("O nome do produto é obrigatório");
    } else {
      setHasErrorOnProductInput("");
    }

    const warrantyTimeFormatted = Number(warrantyTime);

    if (warrantyTimeFormatted <= 0 && warrantyExtension.name) {
      setHasErrorOnWarrantyTime("Digite o tempo de garantia.");
      addToast({
        title: "Ops!!",
        description: "Preencha o campo de tempo (Obrigatório)",
        type: "error",
      });
      return;
    } else {
      setHasErrorOnWarrantyTime("");
    }

    if (warrantyTimeFormatted > 0 && !warrantyExtension.name) {
      setHasErrorOnWarrantyExtension("Selecione uma das opções");

      addToast({
        title: "Ops!!",
        description: "Preencha o campo de tempo (Obrigatório)",
        type: "error",
      });

      return;
    } else {
      setHasErrorOnWarrantyExtension("");
    }

    if (containsEscapeCharacters(observationText)) {
      setHasErrorOnObservationText(
        'Não é permitido uso de barra invertida "\\"'
      );
      addToast({
        title: "Ops!!",
        description: 'Não é permitido uso de barra invertida "\\"',
        type: "error",
      });

      return;
    } else {
      setHasErrorOnObservationText("");
    }

    if (
      !selectedCategoryId ||
      !productInput ||
      (warrantyTimeFormatted <= 0 && warrantyExtension.name) ||
      (warrantyTimeFormatted > 0 && !warrantyExtension)
    ) {
      addToast({
        title: "Ops!!",
        description: "Alguns campos são obrigatórios",
        type: "error",
      });
      return;
    }

    try {
      setIsLoadingButton(true);

      const productData = {
        categoryId: selectedCategoryId,
        name: productInput,
        price: unMaskedPrice ? unMaskedPrice : 0,
        brand: brand,
        observations: observationText,
        warranty: {
          ...(warrantyTimeFormatted !== 0
            ? { time: warrantyTimeFormatted }
            : {}),
          ...(warrantyExtension.name
            ? { extension: warrantyExtension.name }
            : {}),
        },
        photos: photosInState.map((photo) => photo.id),
      };

      if (photosToDeleteBeforeSave.length) {
        await api.delete("/upload/files", {
          params: {
            ids: photosToDeleteBeforeSave.join(","),
          },
        });

        setPhotosToDeleteBeforeSave([]);
      }

      await api.post("products", productData);
      reset();

      refetchListProducts && refetchListProducts();

      handleClearWarrantyExtension();

      addToast({
        title: "Sucesso",
        description: "Produto criado com sucesso",
        type: "success",
      });

      handleGoBack();
    } catch (error) {
      addToast({
        title: "Ops!!",
        description:
          axios.isAxiosError(error) && error.response?.data.error
            ? error.response.data.error
            : "Ocorreu um erro ao criar um novo produto.",
        type: "error",
      });
    } finally {
      setIsLoadingButton(false);
    }
  }

  function handleClearWarrantyExtension() {
    setWarrantyExtension({} as ExtensionProps);
  }

  function handleChangePrice(text: string) {
    setPrice(text);
  }

  async function handleUploadPhoto(photo: FileImageProps) {
    try {
      setLoadingPhoto(true);
      const { data } = await api.post("upload/image", {
        grupo: `produto/${user.indication.hash}`,
        encode: photo.encode,
        extensao: photo.extension,
      });

      setPhotosInState((oldState) => [
        ...oldState,
        { id: data.id, link: data.url },
      ]);
      addToast({
        title: "Sucesso",
        description: "Foto adicionada com sucesso.",
        type: "success",
      });
    } catch (error) {
      addToast({
        title: "Ops!!",
        description:
          axios.isAxiosError(error) && error.response?.data.error
            ? error.response.data.error
            : "Não foi possível adicionar a foto.",
        type: "error",
      });
    } finally {
      setLoadingPhoto(false);
    }
  }

  function handleDeletePhoto(photoId: number) {
    setLoadingPhotoId(photoId);
    setPhotosInState((oldState) =>
      oldState.filter((photo) => photo.id !== photoId)
    );
    setPhotosToDeleteBeforeSave((oldState) => [...oldState, photoId]);
    addToast({
      title: "Sucesso",
      description: "Foto removida com sucesso.",
      type: "success",
    });
  }

  const handleTogglePhotoModal = () => {
    setPhotoModal(!photoModal);
  };
  return (
    <>
      {<LoadingProfiz isVisible={loading} />}

      <CarouselImages
        isVisible={photoModal}
        imageSelected={selectedPhotoIndex}
        images={stagePhotoData}
        onCloseModal={handleTogglePhotoModal}
      />

      <S.Top>
        <S.ButtonClose
          onClick={() => {
            handleGoBack();
          }}
        >
          <img src={arrowLeft} alt="flecha pra esquerda" />
        </S.ButtonClose>

        <p>Novo produto</p>
      </S.Top>

      <S.Content>
        <S.Wrapper>
          <Dropdown
            label="Categoria*"
            placeholder="Selecione uma categoria"
            options={categoriesTree}
            categoryIdSelected={selectedCategoryId}
            // eslint-disable-next-line react/jsx-no-bind
            onClickedValue={handleSelectCategory}
            hasError={hasErrorOnCategory}
          />
        </S.Wrapper>

        <S.Wrapper>
          <Input
            name="Produto*"
            type="text"
            value={productInput}
            placeholder="Nome do produto"
            onChange={(text) =>
              setProductInput(text.currentTarget.value.trimStart())
            }
            hasError={hasErrorOnProductInput}
            onFocus={() => setHasErrorOnProductInput("")}
          />
        </S.Wrapper>

        <S.Wrapper>
          <Input
            name="Marca (opcional)"
            type="text"
            value={brand}
            placeholder="Ex: Consul"
            onChange={(text) => setBrand(text.currentTarget.value.trimStart())}
          />
        </S.Wrapper>

        <S.Wrapper>
          <Input
            name="Valor (opcional)"
            maskType="money"
            valueCurrency={price}
            onChangeCurrency={({ formattedValue, floatValue }) => {
              handleChangePrice(formattedValue);
              setUnmaskedPrice(floatValue!);
            }}
            hasError={hasErrorOnPrice}
            onFocusClearError={() => setHasErrorOnPrice("")}
          />
        </S.Wrapper>

        <S.Wrapper>
          <S.Row>
            <S.WrapperInput>
              <span> Garantia (opcional)</span>
              <Input
                type="number"
                placeholder="Ex: 180"
                value={warrantyTime}
                onChange={(text) => setWarrantyTime(text.currentTarget.value)}
                hasError={hasErrorOnWarrantyTime}
                onFocus={() => setHasErrorOnWarrantyTime("")}
              />
            </S.WrapperInput>

            <S.WrapperDrodown>
              <Dropdown
                placeholder="Selecione"
                options={warrantyExtensionOptions}
                categoryIdSelected={warrantyExtension.id}
                // eslint-disable-next-line react/jsx-no-bind
                onClickedValue={handleWarrantyExtension}
                hasError={hasErrorOnWarrantyExtension}
                clearDropDownItem
                // eslint-disable-next-line react/jsx-no-bind
                handleClear={handleClearWarrantyExtension}
              />
            </S.WrapperDrodown>
          </S.Row>
        </S.Wrapper>

        <S.Wrapper>
          <CommentsTextArea
            label="Observações (opcional)"
            value={observationText}
            onChange={(event) =>
              setObservationText(event.target.value.trimStart())
            }
            hasError={hasErrorOnObservationText}
          />
        </S.Wrapper>

        <S.Wrapper>
          <PhotoCardStep
            title="Adicionar fotos (opcional)"
            maxPhotos={4}
            photosQuantity={photosInState.length}
          >
            <S.ContentImage>
              <S.WrapperImage>
                <AddPhoto
                  maxPhotos={4}
                  photosQuantity={photosInState.length}
                  loading={loadingPhoto}
                  stageID={0}
                  onPictureChoice={handleUploadPhoto}
                />
              </S.WrapperImage>
              {photosInState &&
                photosInState.length > 0 &&
                photosInState.map((photoElement: Photo, index) => {
                  return (
                    <S.WrapperImage key={photoElement.id}>
                      <ServiceRegisterPhoto
                        loading={loadingPhotoId === photoElement.id}
                        key={photoElement.id}
                        url={photoElement.link}
                        onDelete={() => handleDeletePhoto(photoElement.id)}
                        onClick={() => {
                          setStagePhotoData(photosInState ?? []);
                          setSelectedPhotoIndex(index);
                          handleTogglePhotoModal();
                        }}
                      />
                    </S.WrapperImage>
                  );
                })}
            </S.ContentImage>
          </PhotoCardStep>
        </S.Wrapper>

        <S.Wrapper>
          <Button loading={isLoadingButton} onClick={handleUpdateProduct}>
            Salvar
          </Button>
        </S.Wrapper>
      </S.Content>
    </>
  );
}
